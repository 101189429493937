<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="push">
      <div class="branding"><img [src]="logo" [alt]="logo" />
        <span>{{ 'anms.title.short' | translate}}</span>
      </div>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]"
          routerLinkActive="active">
          {{item.label | translate}}
        </a>
        <a mat-list-item href="https://github.com/tomastrajan/angular-ngrx-material-starter" target="_blank"
          rel="noopener noreferrer">
          Github
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
        [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>

          </button>

          <span routerLink="" class="branding spacer center d-inline d-sm-none">
            <img [src]="logo" [alt]="logo" /></span>
          <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none"><img [src]="logo"
              [alt]="logo" /> {{
            'anms.title.short' | translate }}</span>
          <span routerLink="" class="branding spacer d-none d-md-inline"><img [src]="logo" [alt]="logo" /> {{
            'anms.title.long' | translate
            }}</span>

          <span class="d-none d-md-inline">
            <button mat-button class="nav-button" *ngFor="let item of navigation" [routerLink]="[item.link]"
              routerLinkActive="active">
              {{item.label | translate}}
            </button>
          </span>

          <button mat-button mat-stroked-button color="accent" *ngIf="!(isAuthenticated$ | async)"
            (click)="onLoginClick()">
            {{ 'anms.menu.login' | translate }}
          </button>

          <button *ngIf="(isAuthenticated$ | async)" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu">
            <fa-icon icon="user-circle"></fa-icon>
          </button>
          <mat-menu #toolbarUserMenu="matMenu">
            <button mat-menu-item (click)="onLogoutClick()">
              <mat-icon><fa-icon icon="power-off"></fa-icon></mat-icon>
              <span>{{ 'anms.menu.logout' | translate }}</span>
            </button>
          </mat-menu>

          <button mat-icon-button routerLink="settings" class="d-none d-sm-inline">
            <fa-icon icon="cog"></fa-icon>
          </button>

          <a [matTooltip]="'anms.header.github' | translate" matTooltipPosition="before" mat-icon-button
            class="link d-none d-sm-inline" href="https://github.com/tomastrajan/angular-ngrx-material-starter"
            target="_blank" rel="noopener noreferrer">
            <fa-icon [icon]="['fab','github']"></fa-icon>
          </a>

          <span *ngIf="language$ | async as language">
            <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
              <mat-option *ngFor="let l of languages" [value]="l">
                {{ l.toUpperCase() }}
              </mat-option>
            </mat-select>
          </span>
        </mat-toolbar>
      </div>

      <div style="width: 100%; display: flex;" class="content">
        
       

        <div style="width:70%">
          <div *ngIf="coloringType == 'pin'">
            <agm-map [latitude]="lat" [longitude]="lng">
              <agm-marker (markerClick)="markerClicked(d)" [iconUrl]="d.icon" *ngFor="let d of dataset" [latitude]="d.lat"
                [longitude]="d.lng"></agm-marker>
            </agm-map>
          </div>


          <highcharts-chart *ngIf="coloringType == 'map'" id="container" [Highcharts]="Highcharts"
            [constructorType]="chartConstructor" [options]="chartOptions" [callbackFunction]="chartCallback"
            style="width: 100%; height: 100%; display: block;">
          </highcharts-chart>

        </div>
        <div style="width:30%">
          <label id="example-radio-group-label"> <strong> Select an Environment </strong></label>
          <mat-radio-group aria-label="Select an Environment" class="example-radio-group" [(ngModel)]="envType">
            <mat-radio-button class="example-radio-button" selected value="https://api.mapmycustomers.me">Production</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="https://api-test.mapmycustomers.io">Staging</mat-radio-button>
          </mat-radio-group>

          <div>
            <label id="example-radio-group-label"> <strong> Coloring Type</strong></label>
            <mat-radio-group class="example-radio-group" aria-label="Coloring Type" [(ngModel)]="coloringType">
              <mat-radio-button class="example-radio-button" selected value="pin">Pins Coloring</mat-radio-button>
              <mat-radio-button class="example-radio-button" value="map">Map Coloring</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form" *ngIf="!token">
            <mat-form-field appearance="fill">
              <mat-label>username</mat-label>
              <input [(ngModel)]="username" matInput #input  type="email" placeholder="Ex. example@mmc.com">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>password</mat-label>
              <input [(ngModel)]="password" matInput #input  type="password">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="signIn()">SignIn</button>
          </div>
          <div *ngIf="coloringType == 'map'">
            <mat-button-toggle-group name="aggName" aria-label="Font Style" [(ngModel)]="aggName">
              <mat-button-toggle value="count">Doc Count</mat-button-toggle>
              <mat-button-toggle value="avg">Average</mat-button-toggle>
              <mat-button-toggle value="min">Min</mat-button-toggle>
              <mat-button-toggle value="max">Max</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
         

          <div class="tools" *ngIf="token">
            <mat-form-field appearance="fill">
              <mat-label>Bucket count</mat-label>
              <input [(ngModel)]="bucketCount" matInput #input maxlength="1" type="number" placeholder="Ex. Nougat">
            </mat-form-field>
            <mat-form-field hintLabel="Field Name" appearance="fill">
              <mat-label>Field Name</mat-label>
              <input [(ngModel)]="activeField" matInput #input type="text" placeholder="Ex. Nougat">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Classification Type</mat-label>
              <mat-select [(ngModel)]="activeClassification">
                <mat-option *ngFor="let o of classification" [value]="o">{{o}}</mat-option>
              </mat-select>

            </mat-form-field>


            <mat-form-field hintLabel="Field Name" appearance="fill">
              <mat-label>select fields</mat-label>
              <input [(ngModel)]="selectFields" matInput #input type="text" placeholder="Ex. Nougat">
            </mat-form-field>
            <mat-form-field *ngIf="activeClassification == 'Manual'" hintLabel="Range separated by comma" appearance="fill">
              <mat-label>Range</mat-label>
              <input [(ngModel)]="manualRanges" matInput #input type="text" placeholder="eg. 1,10,30">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="run()">Run</button>
            <button mat-raised-button color="warn" (click)="clearStorage()">Clear Token</button>
            <!-- <mat-spinner *ngIf="busy"></mat-spinner> -->
          </div>


          <div class="colors">
            <div *ngIf="legends?.length" [style.background]="nullColor" style="color:white">null</div>
            <div *ngFor="let l of legends; let i = index" class="chips" [style.background]="this.colors[i]">{{l[0]|| '-Infinity'}} to
              {{l[1]}}</div>
          </div>
          <p>Staus: {{status}}</p>
        </div>

        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->

        <!-- <router-outlet #o="outlet"></router-outlet> -->

      </div>

      <div class="footer">

        <div class="row">
          <div class="col-sm-12 links">
            <a href="https://www.github.com/tomastrajan" target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab','github']"></fa-icon>
              <span>Github</span>
            </a>
            <a href="https://www.medium.com/@tomastrajan" target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab','medium-m']"></fa-icon>
              <span>Medium</span>
            </a>
            <a href="https://www.twitter.com/tomastrajan" target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab','twitter']"></fa-icon>
              <span>Twitter</span>
            </a>
            <a href="https://www.youtube.com/channel/UC7XgRHIVoqnh3U5Vmly9ofQ" target="_blank"
              rel="noopener noreferrer">
              <fa-icon [icon]="['fab','youtube']"></fa-icon>
              <span>Youtube</span>
            </a>
            <a href="https://www.instagram.com/tomastrajan" target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab','instagram']"></fa-icon>
              <span>Instagram</span>
            </a>
            <a href="https://www.slides.com/tomastrajan" target="_blank" rel="noopener noreferrer">
              <fa-icon icon="play-circle"></fa-icon>
              <span>Slides</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 signature">
            &#169; <span class="year">{{year}}</span> - Tomas Trajan
            <br class="d-block d-sm-none">
            <a [matTooltip]="'anms.footer.changelog' | translate" matTooltipPosition="before"
              href="https://github.com/tomastrajan/angular-ngrx-material-starter/blob/master/CHANGELOG.md">
              <fa-icon icon="rocket"></fa-icon>
              {{version}} <span *ngIf="!isProd">[{{envName}}]</span>
            </a>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>