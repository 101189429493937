export default function (): Array<any> {
  return [
    ['us-ca-083', 10],
    ['us-fl-087', 11],
    ['us-mi-033', 12],
    ['us-al-097', 13],
    ['us-co-014', 14],
    ['us-wi-003', 15],
    ['us-ma-007', 16],
    ['us-ma-019', 17],
    ['us-fl-037', 18],
    ['us-or-007', 19],
    ['us-co-031', 20],
    ['us-co-005', 21],
    ['us-ny-085', 22],
    ['us-nj-029', 23],
    ['us-sc-019', 24],
    ['us-wa-033', 25],
    ['us-wa-055', 26],
    ['us-me-015', 27],
    ['us-me-009', 28],
    ['us-wa-035', 29],
    ['us-wa-057', 30],
    ['us-nc-019', 31],
    ['us-ga-191', 32],
    ['us-wa-053', 33],
    ['us-va-600', 34],
    ['us-va-059', 35],
    ['us-nm-003', 36],
    ['us-nm-017', 37],
    ['us-nd-019', 38],
    ['us-ut-003', 39],
    ['us-nv-007', 40],
    ['us-or-037', 41],
    ['us-ca-049', 42],
    ['us-sd-121', 43],
    ['us-sd-095', 44],
    ['us-wa-047', 45],
    ['us-tx-371', 46],
    ['us-tx-043', 47],
    ['us-mi-141', 48],
    ['us-mt-005', 49],
    ['us-mt-041', 50],
    ['us-mn-075', 51],
    ['us-nm-031', 52],
    ['us-az-001', 53],
    ['us-tx-243', 54],
    ['us-tx-377', 55],
    ['us-tn-043', 56],
    ['us-mt-019', 57],
    ['us-or-035', 58],
    ['us-or-029', 59],
    ['us-va-750', 60],
    ['us-tn-105', 61],
    ['us-va-580', 62],
    ['us-nd-023', 63],
    ['us-co-097', 64],
    ['us-co-045', 65],
    ['us-co-113', 66],
    ['us-co-085', 67],
    ['us-va-690', 68],
    ['us-la-113', 69],
    ['us-ca-069', 70],
    ['us-ca-053', 71],
    ['us-mn-135', 72],
    ['us-tx-323', 73],
    ['us-nh-007', 74],
    ['us-tx-261', 75],
    ['us-nc-029', 76],
    ['us-nc-053', 77],
    ['us-wy-001', 78],
    ['us-wy-007', 79],
    ['us-ca-027', 80],
    ['us-ca-071', 81],
    ['us-va-515', 82],
    ['us-in-033', 83],
    ['us-in-151', 84],
    ['us-wa-029', 85],
    ['us-va-153', 86],
    ['us-va-179', 87],
    ['us-wa-065', 88],
    ['us-ga-097', 89],
    ['us-ga-067', 90],
    ['us-mt-053', 91],
    ['us-id-021', 92],
    ['us-ga-145', 93],
    ['us-ga-285', 94],
    ['us-wi-107', 95],
    ['us-wi-119', 96],
    ['us-co-107', 97],
    ['us-co-049', 98],
    ['us-in-025', 99],
    ['us-va-820', 100],
    ['us-va-790', 101],
    ['us-va-540', 102],
    ['us-md-037', 103],
    ['us-mt-101', 104],
    ['us-nv-021', 105],
    ['us-nv-001', 106],
    ['us-va-595', 107],
    ['us-tn-037', 108],
    ['us-wi-007', 109],
    ['us-wi-031', 110],
    ['us-oh-065', 111],
    ['us-oh-091', 112],
    ['us-va-678', 113],
    ['us-va-530', 114],
    ['us-va-720', 115],
    ['us-id-073', 116],
    ['us-or-045', 117],
    ['us-ny-057', 118],
    ['us-ny-035', 119],
    ['us-nd-075', 120],
    ['us-nd-009', 121],
    ['us-ca-011', 122],
    ['us-ca-033', 123],
    ['us-az-017', 124],
    ['us-ut-037', 125],
    ['us-co-011', 126],
    ['us-co-061', 127],
    ['us-or-049', 128],
    ['us-or-059', 129],
    ['us-mt-071', 130],
    ['us-va-660', 131],
    ['us-va-683', 132],
    ['us-ut-019', 133],
    ['us-va-840', 134],
    ['us-mt-105', 135],
    ['us-ca-099', 136],
    ['us-ca-047', 137],
    ['us-co-033', 138],
    ['us-co-083', 139],
    ['us-co-067', 140],
    ['us-az-027', 141],
    ['us-az-019', 142],
    ['us-az-013', 143],
    ['us-nv-033', 144],
    ['us-nv-011', 145],
    ['us-mt-063', 146],
    ['us-mt-047', 147],
    ['us-mt-089', 148],
    ['us-ca-111', 149],
    ['us-ca-075', 150],
    ['us-ca-041', 151],
    ['us-ri-009', 152],
    ['us-fl-086', 153],
    ['us-fl-053', 154],
    ['us-mi-019', 155],
    ['us-mi-089', 156],
    ['us-mi-163', 157],
    ['us-fl-017', 158],
    ['us-ny-103', 159],
    ['us-ny-059', 160],
    ['us-co-069', 161],
    ['us-co-013', 162],
    ['us-co-059', 163],
    ['us-nc-049', 164],
    ['us-nc-031', 165],
    ['us-tx-007', 166],
    ['us-wi-113', 167],
    ['us-la-087', 168],
    ['us-md-025', 169],
    ['us-md-005', 170],
    ['us-wa-031', 171],
    ['us-md-041', 172],
    ['us-md-019', 173],
    ['us-md-045', 174],
    ['us-or-057', 175],
    ['us-co-001', 176],
    ['us-co-035', 177],
    ['us-co-093', 178],
    ['us-tx-167', 179],
    ['us-tx-071', 180],
    ['us-tx-201', 181],
    ['us-ga-039', 182],
    ['us-la-075', 183],
    ['us-me-031', 184],
    ['us-me-027', 185],
    ['us-fl-071', 186],
    ['us-fl-015', 187],
    ['us-nj-025', 188],
    ['us-co-075', 189],
    ['us-co-123', 190],
    ['us-ga-179', 191],
    ['us-fl-075', 192],
    ['us-nc-095', 193],
    ['us-nc-055', 194],
    ['us-md-047', 195],
    ['us-md-039', 196],
    ['us-va-001', 197],
    ['us-la-051', 198],
    ['us-la-057', 199],
    ['us-nc-129', 200],
    ['us-wa-069', 201],
    ['us-wa-049', 202],
    ['us-fl-021', 203],
    ['us-fl-051', 204],
    ['us-ms-059', 205],
    ['us-ms-047', 206],
    ['us-la-109', 207],
    ['us-la-101', 208],
    ['us-ny-005', 209],
    ['us-ny-061', 210],
    ['us-ny-047', 211],
    ['us-mi-097', 212],
    ['us-me-029', 213],
    ['us-me-013', 214],
    ['us-mi-029', 215],
    ['us-ri-005', 216],
    ['us-wa-045', 217],
    ['us-wa-067', 218],
    ['us-va-131', 219],
    ['us-ga-127', 220],
    ['us-nj-001', 221],
    ['us-nj-009', 222],
    ['us-wi-029', 223],
    ['us-md-033', 224],
    ['us-tx-057', 225],
    ['us-tx-391', 226],
    ['us-tx-321', 227],
    ['us-az-011', 228],
    ['us-nv-015', 229],
    ['us-wy-031', 230],
    ['us-wy-021', 231],
    ['us-tn-149', 232],
    ['us-ne-031', 233],
    ['us-nv-023', 234],
    ['us-nv-017', 235],
    ['us-nv-003', 236],
    ['us-ut-027', 237],
    ['us-fl-029', 238],
    ['us-fl-067', 239],
    ['us-fl-121', 240],
    ['us-ut-023', 241],
    ['us-wa-019', 242],
    ['us-wa-073', 243],
    ['us-oh-003', 244],
    ['us-oh-137', 245],
    ['us-in-135', 246],
    ['us-in-065', 247],
    ['us-in-177', 248],
    ['us-tx-443', 249],
    ['us-tx-105', 250],
    ['us-tn-125', 251],
    ['us-va-685', 252],
    ['us-in-119', 253],
    ['us-in-105', 254],
    ['us-in-093', 255],
    ['us-in-071', 256],
    ['us-in-101', 257],
    ['us-co-009', 258],
    ['us-co-071', 259],
    ['us-nc-137', 260],
    ['us-nc-013', 261],
    ['us-nm-043', 262],
    ['us-pa-101', 263],
    ['us-nj-007', 264],
    ['us-nc-133', 265],
    ['us-nc-103', 266],
    ['us-md-035', 267],
    ['us-wi-117', 268],
    ['us-ne-105', 269],
    ['us-co-125', 270],
    ['us-tx-389', 271],
    ['us-nc-187', 272],
    ['us-sc-015', 273],
    ['us-tn-021', 274],
    ['us-la-095', 275],
    ['us-ma-005', 276],
    ['us-ri-001', 277],
    ['us-ma-023', 278],
    ['us-wa-041', 279],
    ['us-wa-027', 280],
    ['us-mt-091', 281],
    ['us-md-003', 282],
    ['us-al-095', 283],
    ['us-al-071', 284],
    ['us-tn-051', 285],
    ['us-tn-031', 286],
    ['us-tn-145', 287],
    ['us-ga-293', 288],
    ['us-ga-231', 289],
    ['us-va-191', 290],
    ['us-va-520', 291],
    ['us-ga-249', 292],
    ['us-ga-269', 293],
    ['us-ga-079', 294],
    ['us-ga-197', 295],
    ['us-va-095', 296],
    ['us-va-830', 297],
    ['us-ga-273', 298],
    ['us-ga-037', 299],
    ['us-ga-177', 300],
    ['us-oh-063', 301],
    ['us-oh-173', 302],
    ['us-oh-175', 303],
    ['us-ut-033', 304],
    ['us-wy-023', 305],
    ['us-wy-037', 306],
    ['us-id-007', 307],
    ['us-wy-039', 308],
    ['us-id-081', 309],
    ['us-la-023', 310],
    ['us-la-019', 311],
    ['us-tx-361', 312],
    ['us-mi-007', 313],
    ['us-tn-137', 314],
    ['us-ky-053', 315],
    ['us-ky-231', 316],
    ['us-fl-129', 317],
    ['us-fl-131', 318],
    ['us-fl-059', 319],
    ['us-fl-133', 320],
    ['us-ne-059', 321],
    ['us-ne-035', 322],
    ['us-ne-181', 323],
    ['us-ne-129', 324],
    ['us-ks-157', 325],
    ['us-ne-001', 326],
    ['us-mi-109', 327],
    ['us-mi-103', 328],
    ['us-mi-043', 329],
    ['us-mi-003', 330],
    ['us-wy-041', 331],
    ['us-sd-067', 332],
    ['us-sd-043', 333],
    ['us-in-069', 334],
    ['us-in-003', 335],
    ['us-ga-025', 336],
    ['us-ga-049', 337],
    ['us-ga-015', 338],
    ['us-ga-129', 339],
    ['us-ga-115', 340],
    ['us-ga-233', 341],
    ['us-ne-089', 342],
    ['us-ne-015', 343],
    ['us-ne-103', 344],
    ['us-mo-083', 345],
    ['us-mo-101', 346],
    ['us-mo-107', 347],
    ['us-wi-093', 348],
    ['us-wi-033', 349],
    ['us-co-109', 350],
    ['us-co-003', 351],
    ['us-co-027', 352],
    ['us-wi-063', 353],
    ['us-wi-053', 354],
    ['us-ny-101', 355],
    ['us-ny-003', 356],
    ['us-ny-121', 357],
    ['us-ny-051', 358],
    ['us-ga-063', 359],
    ['us-ga-089', 360],
    ['us-ks-111', 361],
    ['us-ks-073', 362],
    ['us-ks-031', 363],
    ['us-ks-207', 364],
    ['us-in-111', 365],
    ['us-il-091', 366],
    ['us-in-073', 367],
    ['us-in-149', 368],
    ['us-in-131', 369],
    ['us-in-181', 370],
    ['us-in-007', 371],
    ['us-tx-245', 372],
    ['us-al-049', 373],
    ['us-al-019', 374],
    ['us-mt-065', 375],
    ['us-mt-087', 376],
    ['us-co-017', 377],
    ['us-ks-199', 378],
    ['us-co-111', 379],
    ['us-ut-057', 380],
    ['us-ut-005', 381],
    ['us-id-041', 382],
    ['us-tx-327', 383],
    ['us-tx-413', 384],
    ['us-me-023', 385],
    ['us-me-001', 386],
    ['us-me-011', 387],
    ['us-mt-059', 388],
    ['us-mt-097', 389],
    ['us-ok-095', 390],
    ['us-tx-181', 391],
    ['us-wv-103', 392],
    ['us-pa-059', 393],
    ['us-pa-051', 394],
    ['us-al-091', 395],
    ['us-al-065', 396],
    ['us-al-105', 397],
    ['us-ky-209', 398],
    ['us-ky-017', 399],
    ['us-il-113', 400],
    ['us-il-147', 401],
    ['us-il-203', 402],
    ['us-il-019', 403],
    ['us-il-053', 404],
    ['us-il-139', 405],
    ['us-al-063', 406],
    ['us-id-051', 407],
    ['us-id-033', 408],
    ['us-nc-037', 409],
    ['us-nc-063', 410],
    ['us-ia-151', 411],
    ['us-ia-187', 412],
    ['us-ia-025', 413],
    ['us-ia-027', 414],
    ['us-tx-147', 415],
    ['us-tx-119', 416],
    ['us-tn-159', 417],
    ['us-tn-169', 418],
    ['us-tx-127', 419],
    ['us-tx-507', 420],
    ['us-fl-011', 421],
    ['us-mi-151', 422],
    ['us-nc-145', 423],
    ['us-ms-151', 424],
    ['us-ms-055', 425],
    ['us-la-035', 426],
    ['us-ms-125', 427],
    ['us-ar-085', 428],
    ['us-ar-001', 429],
    ['us-tx-027', 430],
    ['us-tx-309', 431],
    ['us-fl-099', 432],
    ['us-ga-073', 433],
    ['us-ga-181', 434],
    ['us-ga-189', 435],
    ['us-ky-003', 436],
    ['us-tn-165', 437],
    ['us-or-051', 438],
    ['us-or-005', 439],
    ['us-tn-115', 440],
    ['us-tn-061', 441],
    ['us-in-179', 442],
    ['us-in-009', 443],
    ['us-va-121', 444],
    ['us-va-063', 445],
    ['us-ms-145', 446],
    ['us-ms-009', 447],
    ['us-ia-109', 448],
    ['us-ia-147', 449],
    ['us-ia-063', 450],
    ['us-ia-059', 451],
    ['us-ia-053', 452],
    ['us-ia-159', 453],
    ['us-nc-001', 454],
    ['us-nc-151', 455],
    ['us-in-171', 456],
    ['us-ne-109', 457],
    ['us-ne-151', 458],
    ['us-ia-031', 459],
    ['us-ia-045', 460],
    ['us-va-073', 461],
    ['us-va-097', 462],
    ['us-va-119', 463],
    ['us-pa-123', 464],
    ['us-pa-053', 465],
    ['us-pa-065', 466],
    ['us-in-089', 467],
    ['us-il-197', 468],
    ['us-tn-003', 469],
    ['us-tn-027', 470],
    ['us-wa-013', 471],
    ['us-or-063', 472],
    ['us-id-049', 473],
    ['us-or-001', 474],
    ['us-ky-127', 475],
    ['us-ky-175', 476],
    ['us-il-025', 477],
    ['us-il-049', 478],
    ['us-il-051', 479],
    ['us-il-035', 480],
    ['us-tn-123', 481],
    ['us-in-157', 482],
    ['us-in-107', 483],
    ['us-in-063', 484],
    ['us-tx-277', 485],
    ['us-nd-035', 486],
    ['us-nd-099', 487],
    ['us-nd-067', 488],
    ['us-ks-071', 489],
    ['us-co-099', 490],
    ['us-ks-075', 491],
    ['us-mo-057', 492],
    ['us-mo-167', 493],
    ['us-mo-109', 494],
    ['us-il-029', 495],
    ['us-il-033', 496],
    ['us-il-079', 497],
    ['us-ia-073', 498],
    ['us-pa-031', 499],
    ['us-in-117', 500],
    ['us-in-037', 501],
    ['us-in-027', 502],
    ['us-in-083', 503],
    ['us-in-123', 504],
    ['us-in-173', 505],
    ['us-in-147', 506],
    ['us-in-163', 507],
    ['us-va-057', 508],
    ['us-mn-043', 509],
    ['us-mn-147', 510],
    ['us-mn-047', 511],
    ['us-ia-195', 512],
    ['us-va-167', 513],
    ['us-va-027', 514],
    ['us-wv-047', 515],
    ['us-co-089', 516],
    ['us-ok-135', 517],
    ['us-ok-079', 518],
    ['us-ok-101', 519],
    ['us-mo-043', 520],
    ['us-mo-067', 521],
    ['us-mo-215', 522],
    ['us-in-115', 523],
    ['us-in-137', 524],
    ['us-in-031', 525],
    ['us-in-139', 526],
    ['us-la-021', 527],
    ['us-la-049', 528],
    ['us-mt-015', 529],
    ['us-mt-013', 530],
    ['us-mt-073', 531],
    ['us-ct-007', 532],
    ['us-ct-011', 533],
    ['us-ct-015', 534],
    ['us-in-161', 535],
    ['us-oh-017', 536],
    ['us-oh-165', 537],
    ['us-mo-145', 538],
    ['us-mo-119', 539],
    ['us-ne-033', 540],
    ['us-ne-007', 541],
    ['us-ne-157', 542],
    ['us-wy-015', 543],
    ['us-ne-165', 544],
    ['us-ga-263', 545],
    ['us-al-081', 546],
    ['us-nd-081', 547],
    ['us-nd-021', 548],
    ['us-mn-085', 549],
    ['us-mn-143', 550],
    ['us-ga-171', 551],
    ['us-ca-097', 552],
    ['us-ca-045', 553],
    ['us-id-065', 554],
    ['us-id-019', 555],
    ['us-ga-297', 556],
    ['us-ga-013', 557],
    ['us-ok-115', 558],
    ['us-ok-035', 559],
    ['us-ks-021', 560],
    ['us-pa-033', 561],
    ['us-il-167', 562],
    ['us-il-135', 563],
    ['us-il-005', 564],
    ['us-ms-071', 565],
    ['us-ms-115', 566],
    ['us-mn-055', 567],
    ['us-mn-045', 568],
    ['us-ia-191', 569],
    ['us-mn-099', 570],
    ['us-mn-109', 571],
    ['us-mn-039', 572],
    ['us-sd-097', 573],
    ['us-sd-087', 574],
    ['us-sd-099', 575],
    ['us-sd-101', 576],
    ['us-al-017', 577],
    ['us-in-047', 578],
    ['us-mn-121', 579],
    ['us-mn-067', 580],
    ['us-tx-177', 581],
    ['us-tx-149', 582],
    ['us-tx-011', 583],
    ['us-tx-045', 584],
    ['us-mi-071', 585],
    ['us-mi-131', 586],
    ['us-mn-133', 587],
    ['us-mn-105', 588],
    ['us-ia-119', 589],
    ['us-la-111', 590],
    ['us-la-061', 591],
    ['us-la-027', 592],
    ['us-pa-043', 593],
    ['us-pa-097', 594],
    ['us-pa-067', 595],
    ['us-pa-099', 596],
    ['us-pa-071', 597],
    ['us-pa-041', 598],
    ['us-ok-027', 599],
    ['us-ok-017', 600],
    ['us-ok-015', 601],
    ['us-wv-079', 602],
    ['us-wv-011', 603],
    ['us-ar-137', 604],
    ['us-ar-141', 605],
    ['us-co-119', 606],
    ['us-co-065', 607],
    ['us-co-117', 608],
    ['us-ny-089', 609],
    ['us-ny-045', 610],
    ['us-ny-041', 611],
    ['us-ny-091', 612],
    ['us-va-005', 613],
    ['us-va-045', 614],
    ['us-va-023', 615],
    ['us-mo-081', 616],
    ['us-ga-045', 617],
    ['us-ga-223', 618],
    ['us-tn-041', 619],
    ['us-tn-141', 620],
    ['us-nd-013', 621],
    ['us-nd-101', 622],
    ['us-ma-027', 623],
    ['us-nh-011', 624],
    ['us-nh-005', 625],
    ['us-ny-111', 626],
    ['us-ny-027', 627],
    ['us-tx-225', 628],
    ['us-tx-313', 629],
    ['us-tx-041', 630],
    ['us-tx-477', 631],
    ['us-co-101', 632],
    ['us-co-041', 633],
    ['us-mo-105', 634],
    ['us-sc-033', 635],
    ['us-nc-155', 636],
    ['us-nc-051', 637],
    ['us-mi-001', 638],
    ['us-ca-067', 639],
    ['us-nv-005', 640],
    ['us-ca-017', 641],
    ['us-ca-061', 642],
    ['us-ca-115', 643],
    ['us-ar-129', 644],
    ['us-nc-093', 645],
    ['us-nc-165', 646],
    ['us-mo-005', 647],
    ['us-ia-071', 648],
    ['us-mo-087', 649],
    ['us-ne-131', 650],
    ['us-pa-023', 651],
    ['us-sd-073', 652],
    ['us-sd-015', 653],
    ['us-sd-023', 654],
    ['us-tn-177', 655],
    ['us-tn-185', 656],
    ['us-il-023', 657],
    ['us-sc-035', 658],
    ['us-ks-139', 659],
    ['us-oh-133', 660],
    ['us-oh-155', 661],
    ['us-oh-007', 662],
    ['us-oh-085', 663],
    ['us-pa-019', 664],
    ['us-pa-005', 665],
    ['us-pa-073', 666],
    ['us-pa-007', 667],
    ['us-ks-113', 668],
    ['us-ks-115', 669],
    ['us-mo-225', 670],
    ['us-mn-033', 671],
    ['us-al-057', 672],
    ['us-al-125', 673],
    ['us-al-099', 674],
    ['us-al-013', 675],
    ['us-al-035', 676],
    ['us-al-131', 677],
    ['us-al-025', 678],
    ['us-ms-015', 679],
    ['us-ms-097', 680],
    ['us-ne-099', 681],
    ['us-il-161', 682],
    ['us-ar-113', 683],
    ['us-ar-133', 684],
    ['us-wy-043', 685],
    ['us-wy-013', 686],
    ['us-ny-097', 687],
    ['us-ar-069', 688],
    ['us-wi-123', 689],
    ['us-wi-111', 690],
    ['us-wi-049', 691],
    ['us-wi-025', 692],
    ['us-wi-043', 693],
    ['us-mo-123', 694],
    ['us-mo-093', 695],
    ['us-tx-289', 696],
    ['us-tx-005', 697],
    ['us-ks-195', 698],
    ['us-ks-051', 699],
    ['us-ks-165', 700],
    ['us-ks-187', 701],
    ['us-tx-463', 702],
    ['us-tx-325', 703],
    ['us-tx-019', 704],
    ['us-tx-029', 705],
    ['us-tx-013', 706],
    ['us-mn-063', 707],
    ['us-mn-165', 708],
    ['us-ok-063', 709],
    ['us-ok-029', 710],
    ['us-tx-103', 711],
    ['us-tx-461', 712],
    ['us-in-051', 713],
    ['us-in-129', 714],
    ['us-va-510', 715],
    ['us-dc-001', 716],
    ['us-va-013', 717],
    ['us-co-037', 718],
    ['us-fl-109', 719],
    ['us-ms-019', 720],
    ['us-mo-079', 721],
    ['us-mo-117', 722],
    ['us-mi-123', 723],
    ['us-mi-085', 724],
    ['us-mi-133', 725],
    ['us-il-031', 726],
    ['us-il-059', 727],
    ['us-al-033', 728],
    ['us-ms-141', 729],
    ['us-ms-003', 730],
    ['us-tn-071', 731],
    ['us-ms-139', 732],
    ['us-wv-051', 733],
    ['us-wv-035', 734],
    ['us-id-037', 735],
    ['us-id-039', 736],
    ['us-id-013', 737],
    ['us-id-063', 738],
    ['us-oh-131', 739],
    ['us-oh-145', 740],
    ['us-oh-087', 741],
    ['us-oh-001', 742],
    ['us-oh-015', 743],
    ['us-fl-055', 744],
    ['us-fl-027', 745],
    ['us-mo-209', 746],
    ['us-ar-015', 747],
    ['us-va-089', 748],
    ['us-nc-169', 749],
    ['us-nc-157', 750],
    ['us-ia-121', 751],
    ['us-ia-039', 752],
    ['us-tx-203', 753],
    ['us-tx-401', 754],
    ['us-ia-181', 755],
    ['us-oh-161', 756],
    ['us-oh-125', 757],
    ['us-fl-043', 758],
    ['us-ga-199', 759],
    ['us-ga-077', 760],
    ['us-co-007', 761],
    ['us-co-105', 762],
    ['us-co-023', 763],
    ['us-tx-099', 764],
    ['us-mo-019', 765],
    ['us-mo-089', 766],
    ['us-mi-139', 767],
    ['us-mi-009', 768],
    ['us-nc-153', 769],
    ['us-sc-069', 770],
    ['us-nc-007', 771],
    ['us-id-055', 772],
    ['us-id-009', 773],
    ['us-il-011', 774],
    ['us-il-073', 775],
    ['us-il-037', 776],
    ['us-il-103', 777],
    ['us-ne-127', 778],
    ['us-mi-035', 779],
    ['us-mi-143', 780],
    ['us-ca-091', 781],
    ['us-ca-035', 782],
    ['us-fl-081', 783],
    ['us-co-015', 784],
    ['us-co-043', 785],
    ['us-ia-089', 786],
    ['us-ia-037', 787],
    ['us-ia-017', 788],
    ['us-ia-065', 789],
    ['us-ia-043', 790],
    ['us-il-093', 791],
    ['us-nd-089', 792],
    ['us-nd-057', 793],
    ['us-nd-025', 794],
    ['us-mi-095', 795],
    ['us-mi-153', 796],
    ['us-ok-019', 797],
    ['us-ok-099', 798],
    ['us-nm-055', 799],
    ['us-ar-017', 800],
    ['us-ms-011', 801],
    ['us-wv-027', 802],
    ['us-md-001', 803],
    ['us-wv-057', 804],
    ['us-pa-057', 805],
    ['us-tx-077', 806],
    ['us-tx-237', 807],
    ['us-tx-367', 808],
    ['us-id-069', 809],
    ['us-mo-221', 810],
    ['us-ny-109', 811],
    ['us-ny-011', 812],
    ['us-ny-075', 813],
    ['us-ny-053', 814],
    ['us-tx-363', 815],
    ['us-tx-143', 816],
    ['us-tx-133', 817],
    ['us-tx-059', 818],
    ['us-tx-417', 819],
    ['us-tx-207', 820],
    ['us-va-085', 821],
    ['us-va-075', 822],
    ['us-oh-033', 823],
    ['us-oh-139', 824],
    ['us-il-067', 825],
    ['us-il-001', 826],
    ['us-il-009', 827],
    ['us-il-075', 828],
    ['us-ga-113', 829],
    ['us-ga-265', 830],
    ['us-ga-141', 831],
    ['us-tx-091', 832],
    ['us-tx-259', 833],
    ['us-ny-001', 834],
    ['us-ny-039', 835],
    ['us-ok-089', 836],
    ['us-ga-019', 837],
    ['us-ga-277', 838],
    ['us-ga-155', 839],
    ['us-ga-321', 840],
    ['us-ga-287', 841],
    ['us-ga-081', 842],
    ['us-ga-075', 843],
    ['us-sd-025', 844],
    ['us-sd-077', 845],
    ['us-sd-005', 846],
    ['us-ny-043', 847],
    ['us-ny-065', 848],
    ['us-mi-053', 849],
    ['us-mn-027', 850],
    ['us-mn-005', 851],
    ['us-mn-107', 852],
    ['us-mn-057', 853],
    ['us-tn-013', 854],
    ['us-tn-173', 855],
    ['us-tn-057', 856],
    ['us-nd-015', 857],
    ['us-nd-029', 858],
    ['us-nd-047', 859],
    ['us-tx-039', 860],
    ['us-tx-157', 861],
    ['us-tx-481', 862],
    ['us-tn-009', 863],
    ['us-mt-031', 864],
    ['us-ga-143', 865],
    ['us-il-041', 866],
    ['us-va-177', 867],
    ['us-va-137', 868],
    ['us-va-079', 869],
    ['us-wi-027', 870],
    ['us-wi-055', 871],
    ['us-wi-047', 872],
    ['us-wi-137', 873],
    ['us-ar-049', 874],
    ['us-ar-065', 875],
    ['us-ar-063', 876],
    ['us-ca-031', 877],
    ['us-ca-087', 878],
    ['us-mo-125', 879],
    ['us-mo-131', 880],
    ['us-nc-005', 881],
    ['us-nc-193', 882],
    ['us-nc-189', 883],
    ['us-nc-027', 884],
    ['us-ks-043', 885],
    ['us-mo-021', 886],
    ['us-mo-003', 887],
    ['us-mo-165', 888],
    ['us-mo-047', 889],
    ['us-ks-005', 890],
    ['us-ne-177', 891],
    ['us-ia-085', 892],
    ['us-ne-021', 893],
    ['us-ia-009', 894],
    ['us-ia-165', 895],
    ['us-md-013', 896],
    ['us-ia-023', 897],
    ['us-ia-013', 898],
    ['us-ia-011', 899],
    ['us-ky-129', 900],
    ['us-ky-189', 901],
    ['us-ky-051', 902],
    ['us-pa-015', 903],
    ['us-pa-117', 904],
    ['us-oh-029', 905],
    ['us-wv-029', 906],
    ['us-mo-051', 907],
    ['us-mi-159', 908],
    ['us-mi-027', 909],
    ['us-mi-021', 910],
    ['us-in-091', 911],
    ['us-in-127', 912],
    ['us-pa-061', 913],
    ['us-va-141', 914],
    ['us-mi-119', 915],
    ['us-mi-135', 916],
    ['us-nm-007', 917],
    ['us-il-133', 918],
    ['us-il-163', 919],
    ['us-il-189', 920],
    ['us-pa-009', 921],
    ['us-pa-013', 922],
    ['us-ar-083', 923],
    ['us-ar-047', 924],
    ['us-ar-127', 925],
    ['us-nc-003', 926],
    ['us-vt-025', 927],
    ['us-nh-019', 928],
    ['us-vt-027', 929],
    ['us-nh-013', 930],
    ['us-mn-089', 931],
    ['us-mn-113', 932],
    ['us-va-101', 933],
    ['us-co-055', 934],
    ['us-tx-255', 935],
    ['us-tx-123', 936],
    ['us-ut-029', 937],
    ['us-ut-011', 938],
    ['us-tx-397', 939],
    ['us-tx-113', 940],
    ['us-tx-257', 941],
    ['us-tn-087', 942],
    ['us-il-159', 943],
    ['us-il-047', 944],
    ['us-il-191', 945],
    ['us-il-185', 946],
    ['us-al-009', 947],
    ['us-mt-051', 948],
    ['us-tx-311', 949],
    ['us-tx-131', 950],
    ['us-tx-247', 951],
    ['us-tx-215', 952],
    ['us-tx-047', 953],
    ['us-mo-163', 954],
    ['us-mo-173', 955],
    ['us-tx-251', 956],
    ['us-or-023', 957],
    ['us-tx-159', 958],
    ['us-ny-113', 959],
    ['us-va-157', 960],
    ['us-va-113', 961],
    ['us-ga-057', 962],
    ['us-fl-095', 963],
    ['us-fl-069', 964],
    ['us-ok-111', 965],
    ['us-in-099', 966],
    ['us-in-049', 967],
    ['us-in-169', 968],
    ['us-mn-169', 969],
    ['us-ms-149', 970],
    ['us-ms-021', 971],
    ['us-la-107', 972],
    ['us-mo-147', 973],
    ['us-mo-139', 974],
    ['us-ks-099', 975],
    ['us-nc-131', 976],
    ['us-nc-083', 977],
    ['us-ky-193', 978],
    ['us-ky-133', 979],
    ['us-pa-035', 980],
    ['us-ok-131', 981],
    ['us-ok-147', 982],
    ['us-ok-113', 983],
    ['us-wv-061', 984],
    ['us-wv-077', 985],
    ['us-wv-023', 986],
    ['us-wv-071', 987],
    ['us-wi-135', 988],
    ['us-wi-115', 989],
    ['us-wi-087', 990],
    ['us-tx-435', 991],
    ['us-tx-137', 992],
    ['us-tx-265', 993],
    ['us-tx-271', 994],
    ['us-mo-153', 995],
    ['us-tx-473', 996],
    ['us-al-053', 997],
    ['us-vt-009', 998],
    ['us-nh-009', 999],
    ['us-vt-017', 1000],
    ['us-ga-137', 1001],
    ['us-ga-139', 1002],
    ['us-ga-157', 1003],
    ['us-ga-135', 1004],
    ['us-ga-279', 1005],
    ['us-ga-283', 1006],
    ['us-ga-061', 1007],
    ['us-wi-125', 1008],
    ['us-wi-041', 1009],
    ['us-mn-007', 1010],
    ['us-mn-077', 1011],
    ['us-md-009', 1012],
    ['us-nm-001', 1013],
    ['us-nm-006', 1014],
    ['us-nm-045', 1015],
    ['us-nm-049', 1016],
    ['us-nm-028', 1017],
    ['us-nm-057', 1018],
    ['us-nj-005', 1019],
    ['us-ga-099', 1020],
    ['us-nd-083', 1021],
    ['us-nd-049', 1022],
    ['us-nd-045', 1023],
    ['us-id-023', 1024],
    ['us-nd-043', 1025],
    ['us-nd-103', 1026],
    ['us-tx-273', 1027],
    ['us-tx-489', 1028],
    ['us-il-055', 1029],
    ['us-il-065', 1030],
    ['us-il-081', 1031],
    ['us-ar-051', 1032],
    ['us-ar-105', 1033],
    ['us-ar-029', 1034],
    ['us-ar-149', 1035],
    ['us-ks-181', 1036],
    ['us-ks-023', 1037],
    ['us-ks-153', 1038],
    ['us-ia-185', 1039],
    ['us-tx-165', 1040],
    ['us-tx-317', 1041],
    ['us-ar-091', 1042],
    ['us-ar-073', 1043],
    ['us-tx-037', 1044],
    ['us-mo-127', 1045],
    ['us-mo-111', 1046],
    ['us-ia-061', 1047],
    ['us-ia-097', 1048],
    ['us-nd-005', 1049],
    ['us-nd-063', 1050],
    ['us-ny-019', 1051],
    ['us-ny-031', 1052],
    ['us-oh-079', 1053],
    ['us-oh-163', 1054],
    ['us-oh-073', 1055],
    ['us-oh-129', 1056],
    ['us-oh-045', 1057],
    ['us-al-085', 1058],
    ['us-ia-113', 1059],
    ['us-ga-215', 1060],
    ['us-tx-003', 1061],
    ['us-tx-495', 1062],
    ['us-nm-025', 1063],
    ['us-nm-015', 1064],
    ['us-oh-149', 1065],
    ['us-oh-021', 1066],
    ['us-oh-109', 1067],
    ['us-oh-023', 1068],
    ['us-tx-117', 1069],
    ['us-nm-037', 1070],
    ['us-tx-359', 1071],
    ['us-ok-133', 1072],
    ['us-sc-081', 1073],
    ['us-sc-003', 1074],
    ['us-ga-251', 1075],
    ['us-ga-033', 1076],
    ['us-ga-165', 1077],
    ['us-ga-245', 1078],
    ['us-tx-305', 1079],
    ['us-tx-169', 1080],
    ['us-wi-079', 1081],
    ['us-wi-089', 1082],
    ['us-sc-047', 1083],
    ['us-sc-059', 1084],
    ['us-sc-045', 1085],
    ['us-sc-001', 1086],
    ['us-sd-019', 1087],
    ['us-wy-011', 1088],
    ['us-wy-005', 1089],
    ['us-mt-011', 1090],
    ['us-mt-025', 1091],
    ['us-nd-011', 1092],
    ['us-ma-013', 1093],
    ['us-ma-015', 1094],
    ['us-tx-055', 1095],
    ['us-tx-209', 1096],
    ['us-al-111', 1097],
    ['us-al-027', 1098],
    ['us-al-037', 1099],
    ['us-ky-095', 1100],
    ['us-ks-161', 1101],
    ['us-ks-061', 1102],
    ['us-tn-163', 1103],
    ['us-tn-019', 1104],
    ['us-va-111', 1105],
    ['us-va-037', 1106],
    ['us-tn-179', 1107],
    ['us-tn-073', 1108],
    ['us-sc-071', 1109],
    ['us-va-181', 1110],
    ['us-va-093', 1111],
    ['us-va-175', 1112],
    ['us-va-800', 1113],
    ['us-va-550', 1114],
    ['us-nc-073', 1115],
    ['us-or-069', 1116],
    ['us-va-061', 1117],
    ['us-in-035', 1118],
    ['us-wi-131', 1119],
    ['us-pa-085', 1120],
    ['us-pa-039', 1121],
    ['us-il-193', 1122],
    ['us-il-039', 1123],
    ['us-il-115', 1124],
    ['us-il-173', 1125],
    ['us-il-137', 1126],
    ['us-ut-039', 1127],
    ['us-mn-049', 1128],
    ['us-nc-173', 1129],
    ['us-nc-099', 1130],
    ['us-fl-103', 1131],
    ['us-fl-101', 1132],
    ['us-fl-119', 1133],
    ['us-nd-091', 1134],
    ['us-nd-003', 1135],
    ['us-nd-073', 1136],
    ['us-nd-097', 1137],
    ['us-nc-011', 1138],
    ['us-wv-107', 1139],
    ['us-wv-073', 1140],
    ['us-wi-097', 1141],
    ['us-sc-063', 1142],
    ['us-md-510', 1143],
    ['us-wi-039', 1144],
    ['us-ok-059', 1145],
    ['us-ok-151', 1146],
    ['us-ok-003', 1147],
    ['us-ks-007', 1148],
    ['us-ky-063', 1149],
    ['us-ma-003', 1150],
    ['us-mo-041', 1151],
    ['us-mo-195', 1152],
    ['us-ks-167', 1153],
    ['us-tn-111', 1154],
    ['us-ar-023', 1155],
    ['us-id-003', 1156],
    ['us-id-045', 1157],
    ['us-ma-021', 1158],
    ['us-pa-037', 1159],
    ['us-pa-107', 1160],
    ['us-nc-109', 1161],
    ['us-nc-045', 1162],
    ['us-ar-019', 1163],
    ['us-ar-059', 1164],
    ['us-il-169', 1165],
    ['us-il-057', 1166],
    ['us-ca-051', 1167],
    ['us-nm-013', 1168],
    ['us-nd-039', 1169],
    ['us-ca-055', 1170],
    ['us-ca-095', 1171],
    ['us-tx-449', 1172],
    ['us-nc-125', 1173],
    ['us-ks-009', 1174],
    ['us-nd-087', 1175],
    ['us-la-083', 1176],
    ['us-ne-041', 1177],
    ['us-ne-071', 1178],
    ['us-al-119', 1179],
    ['us-ms-069', 1180],
    ['us-al-093', 1181],
    ['us-al-133', 1182],
    ['us-fl-117', 1183],
    ['us-ms-131', 1184],
    ['us-ms-109', 1185],
    ['us-ms-035', 1186],
    ['us-ms-073', 1187],
    ['us-mi-037', 1188],
    ['us-mi-065', 1189],
    ['us-ks-149', 1190],
    ['us-ks-177', 1191],
    ['us-sd-061', 1192],
    ['us-sd-035', 1193],
    ['us-mn-061', 1194],
    ['us-co-073', 1195],
    ['us-co-063', 1196],
    ['us-ny-025', 1197],
    ['us-ny-077', 1198],
    ['us-ny-115', 1199],
    ['us-in-005', 1200],
    ['us-in-081', 1201],
    ['us-tx-445', 1202],
    ['us-al-121', 1203],
    ['us-ia-021', 1204],
    ['us-ia-035', 1205],
    ['us-nc-197', 1206],
    ['us-nc-067', 1207],
    ['us-nc-081', 1208],
    ['us-nc-057', 1209],
    ['us-nv-510', 1210],
    ['us-ga-193', 1211],
    ['us-ga-261', 1212],
    ['us-mo-023', 1213],
    ['us-mo-223', 1214],
    ['us-mo-179', 1215],
    ['us-co-081', 1216],
    ['us-wy-025', 1217],
    ['us-wy-009', 1218],
    ['us-wi-065', 1219],
    ['us-il-177', 1220],
    ['us-wi-045', 1221],
    ['us-wi-105', 1222],
    ['us-mi-087', 1223],
    ['us-la-015', 1224],
    ['us-la-119', 1225],
    ['us-ar-027', 1226],
    ['us-ms-119', 1227],
    ['us-ms-143', 1228],
    ['us-ms-135', 1229],
    ['us-ms-161', 1230],
    ['us-la-053', 1231],
    ['us-la-039', 1232],
    ['us-la-079', 1233],
    ['us-tn-023', 1234],
    ['us-tn-077', 1235],
    ['us-tn-069', 1236],
    ['us-az-003', 1237],
    ['us-nm-023', 1238],
    ['us-ok-009', 1239],
    ['us-ok-055', 1240],
    ['us-ok-057', 1241],
    ['us-tx-075', 1242],
    ['us-mo-011', 1243],
    ['us-mo-097', 1244],
    ['us-mo-039', 1245],
    ['us-ar-103', 1246],
    ['us-oh-037', 1247],
    ['us-in-075', 1248],
    ['us-sd-045', 1249],
    ['us-sd-129', 1250],
    ['us-sd-041', 1251],
    ['us-sd-137', 1252],
    ['us-sd-055', 1253],
    ['us-ms-111', 1254],
    ['us-ms-041', 1255],
    ['us-ms-039', 1256],
    ['us-ca-013', 1257],
    ['us-va-087', 1258],
    ['us-ny-119', 1259],
    ['us-ny-087', 1260],
    ['us-ny-071', 1261],
    ['us-nj-003', 1262],
    ['us-mo-017', 1263],
    ['us-wv-059', 1264],
    ['us-wv-033', 1265],
    ['us-wv-049', 1266],
    ['us-nc-077', 1267],
    ['us-ne-179', 1268],
    ['us-ne-139', 1269],
    ['us-sc-005', 1270],
    ['us-nd-051', 1271],
    ['us-ne-023', 1272],
    ['us-ne-143', 1273],
    ['us-ne-141', 1274],
    ['us-ne-185', 1275],
    ['us-nc-185', 1276],
    ['us-nc-069', 1277],
    ['us-de-005', 1278],
    ['us-mo-175', 1279],
    ['us-mo-137', 1280],
    ['us-ks-035', 1281],
    ['us-ks-015', 1282],
    ['us-mi-105', 1283],
    ['us-il-007', 1284],
    ['us-il-201', 1285],
    ['us-wi-127', 1286],
    ['us-sc-007', 1287],
    ['us-sc-077', 1288],
    ['us-in-011', 1289],
    ['us-in-097', 1290],
    ['us-in-023', 1291],
    ['us-in-015', 1292],
    ['us-in-057', 1293],
    ['us-in-095', 1294],
    ['us-il-027', 1295],
    ['us-id-067', 1296],
    ['us-id-031', 1297],
    ['us-id-053', 1298],
    ['us-ms-127', 1299],
    ['us-ms-121', 1300],
    ['us-tx-345', 1301],
    ['us-tx-101', 1302],
    ['us-tx-125', 1303],
    ['us-tx-153', 1304],
    ['us-or-039', 1305],
    ['us-or-003', 1306],
    ['us-ok-137', 1307],
    ['us-ok-033', 1308],
    ['us-tx-485', 1309],
    ['us-mi-155', 1310],
    ['us-ks-089', 1311],
    ['us-ks-123', 1312],
    ['us-ks-029', 1313],
    ['us-ms-091', 1314],
    ['us-il-085', 1315],
    ['us-in-059', 1316],
    ['us-va-620', 1317],
    ['us-tx-329', 1318],
    ['us-ks-101', 1319],
    ['us-ks-171', 1320],
    ['us-il-199', 1321],
    ['us-mt-029', 1322],
    ['us-mt-049', 1323],
    ['us-ks-189', 1324],
    ['us-ky-067', 1325],
    ['us-ky-151', 1326],
    ['us-ky-203', 1327],
    ['us-sd-013', 1328],
    ['us-wv-001', 1329],
    ['us-tn-055', 1330],
    ['us-tn-099', 1331],
    ['us-ne-045', 1332],
    ['us-ne-013', 1333],
    ['us-pa-109', 1334],
    ['us-tx-469', 1335],
    ['us-tx-239', 1336],
    ['us-mi-147', 1337],
    ['us-ne-173', 1338],
    ['us-vt-011', 1339],
    ['us-va-067', 1340],
    ['us-ok-011', 1341],
    ['us-ok-039', 1342],
    ['us-wv-093', 1343],
    ['us-wi-023', 1344],
    ['us-ia-157', 1345],
    ['us-ia-123', 1346],
    ['us-ia-125', 1347],
    ['us-ia-051', 1348],
    ['us-ia-179', 1349],
    ['us-ia-135', 1350],
    ['us-ks-091', 1351],
    ['us-ks-045', 1352],
    ['us-ks-059', 1353],
    ['us-ky-059', 1354],
    ['us-ky-237', 1355],
    ['us-ky-197', 1356],
    ['us-or-061', 1357],
    ['us-ga-151', 1358],
    ['us-nd-001', 1359],
    ['us-nd-041', 1360],
    ['us-sd-105', 1361],
    ['us-nd-085', 1362],
    ['us-nd-037', 1363],
    ['us-pa-063', 1364],
    ['us-pa-021', 1365],
    ['us-tn-107', 1366],
    ['us-mi-093', 1367],
    ['us-mi-075', 1368],
    ['us-co-087', 1369],
    ['us-pa-127', 1370],
    ['us-pa-069', 1371],
    ['us-ok-123', 1372],
    ['us-ok-037', 1373],
    ['us-ok-081', 1374],
    ['us-tn-175', 1375],
    ['us-ok-125', 1376],
    ['us-ct-013', 1377],
    ['us-ga-003', 1378],
    ['us-tx-145', 1379],
    ['us-ks-033', 1380],
    ['us-ky-113', 1381],
    ['us-ky-171', 1382],
    ['us-ok-075', 1383],
    ['us-sd-079', 1384],
    ['us-nc-033', 1385],
    ['us-al-003', 1386],
    ['us-va-127', 1387],
    ['us-ga-053', 1388],
    ['us-ny-049', 1389],
    ['us-in-077', 1390],
    ['us-va-019', 1391],
    ['us-va-009', 1392],
    ['us-va-011', 1393],
    ['us-va-161', 1394],
    ['us-va-775', 1395],
    ['us-nm-033', 1396],
    ['us-ne-077', 1397],
    ['us-ne-011', 1398],
    ['us-mo-149', 1399],
    ['us-tn-161', 1400],
    ['us-tn-083', 1401],
    ['us-pa-113', 1402],
    ['us-pa-131', 1403],
    ['us-in-167', 1404],
    ['us-il-045', 1405],
    ['us-in-165', 1406],
    ['us-mi-149', 1407],
    ['us-in-039', 1408],
    ['us-in-087', 1409],
    ['us-tx-189', 1410],
    ['us-tx-303', 1411],
    ['us-tx-107', 1412],
    ['us-mn-171', 1413],
    ['us-mn-093', 1414],
    ['us-oh-005', 1415],
    ['us-ne-119', 1416],
    ['us-ne-167', 1417],
    ['us-fl-085', 1418],
    ['us-ga-227', 1419],
    ['us-ga-085', 1420],
    ['us-ga-319', 1421],
    ['us-ga-167', 1422],
    ['us-ar-095', 1423],
    ['us-ar-117', 1424],
    ['us-ar-147', 1425],
    ['us-mo-059', 1426],
    ['us-mo-169', 1427],
    ['us-va-143', 1428],
    ['us-ks-145', 1429],
    ['us-ks-137', 1430],
    ['us-ks-147', 1431],
    ['us-ks-065', 1432],
    ['us-ks-063', 1433],
    ['us-ks-109', 1434],
    ['us-la-099', 1435],
    ['us-la-097', 1436],
    ['us-la-055', 1437],
    ['us-oh-095', 1438],
    ['us-oh-123', 1439],
    ['us-oh-069', 1440],
    ['us-ar-031', 1441],
    ['us-ar-093', 1442],
    ['us-ar-035', 1443],
    ['us-ky-117', 1444],
    ['us-ky-081', 1445],
    ['us-ky-077', 1446],
    ['us-ky-097', 1447],
    ['us-ky-001', 1448],
    ['us-ky-217', 1449],
    ['us-nc-075', 1450],
    ['us-va-071', 1451],
    ['us-wv-055', 1452],
    ['us-nm-035', 1453],
    ['us-ne-049', 1454],
    ['us-ne-101', 1455],
    ['us-ne-135', 1456],
    ['us-ne-117', 1457],
    ['us-ks-039', 1458],
    ['us-ne-145', 1459],
    ['us-ne-149', 1460],
    ['us-tn-081', 1461],
    ['us-tn-085', 1462],
    ['us-il-125', 1463],
    ['us-il-107', 1464],
    ['us-va-077', 1465],
    ['us-va-197', 1466],
    ['us-ga-303', 1467],
    ['us-wi-035', 1468],
    ['us-tn-153', 1469],
    ['us-tn-007', 1470],
    ['us-tn-065', 1471],
    ['us-tx-471', 1472],
    ['us-tx-407', 1473],
    ['us-tx-291', 1474],
    ['us-ks-191', 1475],
    ['us-ok-071', 1476],
    ['us-mi-127', 1477],
    ['us-mn-001', 1478],
    ['us-mn-065', 1479],
    ['us-mn-115', 1480],
    ['us-ca-003', 1481],
    ['us-il-105', 1482],
    ['us-oh-135', 1483],
    ['us-ma-025', 1484],
    ['us-ma-017', 1485],
    ['us-tx-115', 1486],
    ['us-ny-069', 1487],
    ['us-ks-121', 1488],
    ['us-mo-037', 1489],
    ['us-mo-095', 1490],
    ['us-ga-153', 1491],
    ['us-ga-023', 1492],
    ['us-ga-235', 1493],
    ['us-ga-091', 1494],
    ['us-ny-023', 1495],
    ['us-mi-161', 1496],
    ['us-in-113', 1497],
    ['us-ga-225', 1498],
    ['us-ga-311', 1499],
    ['us-ga-163', 1500],
    ['us-wi-099', 1501],
    ['us-wi-085', 1502],
    ['us-wi-067', 1503],
    ['us-wi-078', 1504],
    ['us-wi-083', 1505],
    ['us-wa-063', 1506],
    ['us-wa-043', 1507],
    ['us-nj-039', 1508],
    ['us-nj-017', 1509],
    ['us-oh-075', 1510],
    ['us-oh-169', 1511],
    ['us-oh-151', 1512],
    ['us-oh-019', 1513],
    ['us-tx-187', 1514],
    ['us-md-017', 1515],
    ['us-va-033', 1516],
    ['us-va-630', 1517],
    ['us-va-099', 1518],
    ['us-va-193', 1519],
    ['us-de-003', 1520],
    ['us-pa-029', 1521],
    ['us-nj-015', 1522],
    ['us-nj-033', 1523],
    ['us-nj-011', 1524],
    ['us-pa-011', 1525],
    ['us-pa-077', 1526],
    ['us-pa-095', 1527],
    ['us-md-015', 1528],
    ['us-md-029', 1529],
    ['us-ia-101', 1530],
    ['us-ia-177', 1531],
    ['us-ne-137', 1532],
    ['us-ne-073', 1533],
    ['us-ms-087', 1534],
    ['us-ms-095', 1535],
    ['us-fl-033', 1536],
    ['us-fl-113', 1537],
    ['us-tx-439', 1538],
    ['us-va-169', 1539],
    ['us-ok-085', 1540],
    ['us-ga-289', 1541],
    ['us-oh-099', 1542],
    ['us-tx-381', 1543],
    ['us-tx-375', 1544],
    ['us-tx-437', 1545],
    ['us-mo-151', 1546],
    ['us-il-089', 1547],
    ['us-il-043', 1548],
    ['us-sc-031', 1549],
    ['us-sc-055', 1550],
    ['us-sc-057', 1551],
    ['us-nc-119', 1552],
    ['us-nc-139', 1553],
    ['us-or-019', 1554],
    ['us-or-033', 1555],
    ['us-pa-089', 1556],
    ['us-ar-101', 1557],
    ['us-ar-071', 1558],
    ['us-ks-135', 1559],
    ['us-la-047', 1560],
    ['us-la-077', 1561],
    ['us-la-121', 1562],
    ['us-ne-067', 1563],
    ['us-ne-095', 1564],
    ['us-al-115', 1565],
    ['us-ms-101', 1566],
    ['us-ms-099', 1567],
    ['us-ms-123', 1568],
    ['us-ga-095', 1569],
    ['us-id-017', 1570],
    ['us-ga-047', 1571],
    ['us-md-027', 1572],
    ['us-md-021', 1573],
    ['us-ut-035', 1574],
    ['us-ct-003', 1575],
    ['us-ct-009', 1576],
    ['us-oh-157', 1577],
    ['us-oh-067', 1578],
    ['us-oh-081', 1579],
    ['us-oh-013', 1580],
    ['us-oh-111', 1581],
    ['us-oh-059', 1582],
    ['us-oh-047', 1583],
    ['us-oh-027', 1584],
    ['us-oh-071', 1585],
    ['us-mn-071', 1586],
    ['us-id-077', 1587],
    ['us-id-011', 1588],
    ['us-id-029', 1589],
    ['us-wi-121', 1590],
    ['us-sd-117', 1591],
    ['us-sd-071', 1592],
    ['us-va-036', 1593],
    ['us-va-149', 1594],
    ['us-va-041', 1595],
    ['us-al-023', 1596],
    ['us-al-129', 1597],
    ['us-in-055', 1598],
    ['us-ne-047', 1599],
    ['us-ne-111', 1600],
    ['us-nd-105', 1601],
    ['us-nd-017', 1602],
    ['us-il-151', 1603],
    ['us-il-069', 1604],
    ['us-ne-005', 1605],
    ['us-tx-163', 1606],
    ['us-ia-103', 1607],
    ['us-ks-001', 1608],
    ['us-ks-133', 1609],
    ['us-ky-089', 1610],
    ['us-ne-087', 1611],
    ['us-ne-085', 1612],
    ['us-ne-063', 1613],
    ['us-ne-057', 1614],
    ['us-co-115', 1615],
    ['us-co-095', 1616],
    ['us-ky-101', 1617],
    ['us-ms-105', 1618],
    ['us-md-023', 1619],
    ['us-mi-059', 1620],
    ['us-oh-171', 1621],
    ['us-oh-051', 1622],
    ['us-ky-177', 1623],
    ['us-ky-149', 1624],
    ['us-ky-141', 1625],
    ['us-nh-015', 1626],
    ['us-nh-017', 1627],
    ['us-pa-133', 1628],
    ['us-ne-097', 1629],
    ['us-sd-075', 1630],
    ['us-nd-007', 1631],
    ['us-ne-003', 1632],
    ['us-mo-073', 1633],
    ['us-ky-061', 1634],
    ['us-ky-031', 1635],
    ['us-ga-211', 1636],
    ['us-ms-033', 1637],
    ['us-ms-137', 1638],
    ['us-ar-107', 1639],
    ['us-al-117', 1640],
    ['us-ok-149', 1641],
    ['us-nc-135', 1642],
    ['us-in-103', 1643],
    ['us-in-017', 1644],
    ['us-wi-061', 1645],
    ['us-ny-029', 1646],
    ['us-ny-063', 1647],
    ['us-ny-009', 1648],
    ['us-md-031', 1649],
    ['us-va-610', 1650],
    ['us-va-035', 1651],
    ['us-il-083', 1652],
    ['us-mo-183', 1653],
    ['us-mo-071', 1654],
    ['us-ks-013', 1655],
    ['us-ga-029', 1656],
    ['us-ga-031', 1657],
    ['us-ga-109', 1658],
    ['us-ky-119', 1659],
    ['us-ky-071', 1660],
    ['us-ky-159', 1661],
    ['us-ga-309', 1662],
    ['us-nc-107', 1663],
    ['us-tx-483', 1664],
    ['us-tx-211', 1665],
    ['us-tx-393', 1666],
    ['us-tx-295', 1667],
    ['us-ok-007', 1668],
    ['us-ks-175', 1669],
    ['us-or-009', 1670],
    ['us-sc-041', 1671],
    ['us-ut-053', 1672],
    ['us-ut-021', 1673],
    ['us-ut-025', 1674],
    ['us-wy-033', 1675],
    ['us-wy-003', 1676],
    ['us-mt-003', 1677],
    ['us-pa-047', 1678],
    ['us-pa-083', 1679],
    ['us-ms-031', 1680],
    ['us-in-013', 1681],
    ['us-ky-065', 1682],
    ['us-tx-087', 1683],
    ['us-tx-089', 1684],
    ['us-il-063', 1685],
    ['us-mn-073', 1686],
    ['us-sd-051', 1687],
    ['us-sd-039', 1688],
    ['us-mn-081', 1689],
    ['us-mn-083', 1690],
    ['us-ga-043', 1691],
    ['us-tx-497', 1692],
    ['us-ga-307', 1693],
    ['us-ga-159', 1694],
    ['us-ga-169', 1695],
    ['us-ga-009', 1696],
    ['us-ga-021', 1697],
    ['us-ny-079', 1698],
    ['us-va-199', 1699],
    ['us-va-735', 1700],
    ['us-va-700', 1701],
    ['us-ok-065', 1702],
    ['us-tx-173', 1703],
    ['us-tx-431', 1704],
    ['us-tx-335', 1705],
    ['us-tx-415', 1706],
    ['us-tx-383', 1707],
    ['us-ok-093', 1708],
    ['us-wa-051', 1709],
    ['us-nd-055', 1710],
    ['us-or-027', 1711],
    ['us-ia-081', 1712],
    ['us-ia-197', 1713],
    ['us-tx-419', 1714],
    ['us-tx-403', 1715],
    ['us-mo-055', 1716],
    ['us-wv-075', 1717],
    ['us-va-017', 1718],
    ['us-mn-111', 1719],
    ['us-ms-017', 1720],
    ['us-ms-081', 1721],
    ['us-la-067', 1722],
    ['us-al-047', 1723],
    ['us-al-001', 1724],
    ['us-tx-093', 1725],
    ['us-tx-425', 1726],
    ['us-mn-153', 1727],
    ['us-mn-021', 1728],
    ['us-co-079', 1729],
    ['us-nm-039', 1730],
    ['us-ms-163', 1731],
    ['us-mo-177', 1732],
    ['us-oh-041', 1733],
    ['us-oh-117', 1734],
    ['us-nd-031', 1735],
    ['us-ar-033', 1736],
    ['us-ok-001', 1737],
    ['us-ok-041', 1738],
    ['us-tx-053', 1739],
    ['us-tx-411', 1740],
    ['us-tx-299', 1741],
    ['us-il-109', 1742],
    ['us-il-187', 1743],
    ['us-il-071', 1744],
    ['us-ar-067', 1745],
    ['us-ar-037', 1746],
    ['us-tx-067', 1747],
    ['us-tx-315', 1748],
    ['us-ky-083', 1749],
    ['us-tn-079', 1750],
    ['us-tn-005', 1751],
    ['us-nc-087', 1752],
    ['us-nc-115', 1753],
    ['us-mn-037', 1754],
    ['us-or-025', 1755],
    ['us-or-013', 1756],
    ['us-nv-013', 1757],
    ['us-wi-001', 1758],
    ['us-wi-077', 1759],
    ['us-mo-045', 1760],
    ['us-mo-103', 1761],
    ['us-mo-001', 1762],
    ['us-mo-199', 1763],
    ['us-ky-015', 1764],
    ['us-ky-079', 1765],
    ['us-ky-021', 1766],
    ['us-ky-229', 1767],
    ['us-la-115', 1768],
    ['us-la-009', 1769],
    ['us-la-059', 1770],
    ['us-mo-227', 1771],
    ['us-ne-163', 1772],
    ['us-sd-021', 1773],
    ['us-sd-063', 1774],
    ['us-mi-057', 1775],
    ['us-ia-001', 1776],
    ['us-ia-175', 1777],
    ['us-ne-017', 1778],
    ['us-ne-171', 1779],
    ['us-ne-113', 1780],
    ['us-ne-121', 1781],
    ['us-ky-039', 1782],
    ['us-in-021', 1783],
    ['us-in-109', 1784],
    ['us-tx-139', 1785],
    ['us-tx-213', 1786],
    ['us-in-053', 1787],
    ['us-fl-035', 1788],
    ['us-ms-077', 1789],
    ['us-la-127', 1790],
    ['us-il-131', 1791],
    ['us-il-175', 1792],
    ['us-sd-029', 1793],
    ['us-ga-259', 1794],
    ['us-ar-121', 1795],
    ['us-ar-021', 1796],
    ['us-ar-045', 1797],
    ['us-ia-107', 1798],
    ['us-il-061', 1799],
    ['us-il-171', 1800],
    ['us-ca-105', 1801],
    ['us-ca-023', 1802],
    ['us-mn-025', 1803],
    ['us-mn-003', 1804],
    ['us-sd-009', 1805],
    ['us-sd-135', 1806],
    ['us-tn-059', 1807],
    ['us-va-183', 1808],
    ['us-in-141', 1809],
    ['us-ga-119', 1810],
    ['us-ga-257', 1811],
    ['us-ar-005', 1812],
    ['us-ms-007', 1813],
    ['us-ks-011', 1814],
    ['us-va-049', 1815],
    ['us-va-147', 1816],
    ['us-va-145', 1817],
    ['us-sc-085', 1818],
    ['us-wv-045', 1819],
    ['us-wv-109', 1820],
    ['us-wv-005', 1821],
    ['us-ca-029', 1822],
    ['us-tx-097', 1823],
    ['us-sc-075', 1824],
    ['us-ia-131', 1825],
    ['us-mn-101', 1826],
    ['us-mn-117', 1827],
    ['us-ri-007', 1828],
    ['us-mo-161', 1829],
    ['us-ms-117', 1830],
    ['us-ky-121', 1831],
    ['us-ky-235', 1832],
    ['us-ky-013', 1833],
    ['us-tx-491', 1834],
    ['us-ga-107', 1835],
    ['us-la-025', 1836],
    ['us-pa-025', 1837],
    ['us-ne-133', 1838],
    ['us-tn-171', 1839],
    ['us-sc-083', 1840],
    ['us-sc-021', 1841],
    ['us-nc-161', 1842],
    ['us-wi-005', 1843],
    ['us-wi-073', 1844],
    ['us-wv-101', 1845],
    ['us-wv-007', 1846],
    ['us-wv-015', 1847],
    ['us-wv-067', 1848],
    ['us-mo-085', 1849],
    ['us-mo-009', 1850],
    ['us-mn-123', 1851],
    ['us-ar-081', 1852],
    ['us-nv-019', 1853],
    ['us-ms-085', 1854],
    ['us-ms-005', 1855],
    ['us-ms-113', 1856],
    ['us-ms-147', 1857],
    ['us-la-105', 1858],
    ['us-id-085', 1859],
    ['us-id-059', 1860],
    ['us-ky-091', 1861],
    ['us-ky-183', 1862],
    ['us-ia-137', 1863],
    ['us-ia-129', 1864],
    ['us-ne-153', 1865],
    ['us-ne-025', 1866],
    ['us-ne-055', 1867],
    ['us-ga-071', 1868],
    ['us-il-157', 1869],
    ['us-mo-157', 1870],
    ['us-va-173', 1871],
    ['us-nm-011', 1872],
    ['us-nm-041', 1873],
    ['us-nm-027', 1874],
    ['us-pa-111', 1875],
    ['us-oh-101', 1876],
    ['us-il-149', 1877],
    ['us-al-107', 1878],
    ['us-ok-045', 1879],
    ['us-il-145', 1880],
    ['us-ia-075', 1881],
    ['us-co-019', 1882],
    ['us-co-103', 1883],
    ['us-nj-035', 1884],
    ['us-nj-023', 1885],
    ['us-il-087', 1886],
    ['us-il-127', 1887],
    ['us-co-053', 1888],
    ['us-co-091', 1889],
    ['us-mn-059', 1890],
    ['us-pa-049', 1891],
    ['us-ms-029', 1892],
    ['us-ky-161', 1893],
    ['us-mn-095', 1894],
    ['us-tx-179', 1895],
    ['us-tx-129', 1896],
    ['us-fl-013', 1897],
    ['us-fl-005', 1898],
    ['us-tn-181', 1899],
    ['us-al-077', 1900],
    ['us-al-083', 1901],
    ['us-in-045', 1902],
    ['us-oh-115', 1903],
    ['us-oh-127', 1904],
    ['us-va-590', 1905],
    ['us-ga-011', 1906],
    ['us-ga-229', 1907],
    ['us-ga-305', 1908],
    ['us-ok-109', 1909],
    ['us-ok-069', 1910],
    ['us-ok-005', 1911],
    ['us-ut-043', 1912],
    ['us-ut-013', 1913],
    ['us-nv-027', 1914],
    ['us-ky-023', 1915],
    ['us-ky-201', 1916],
    ['us-wi-037', 1917],
    ['us-wi-075', 1918],
    ['us-mn-159', 1919],
    ['us-ky-043', 1920],
    ['us-nc-097', 1921],
    ['us-nc-159', 1922],
    ['us-nc-025', 1923],
    ['us-nc-167', 1924],
    ['us-va-083', 1925],
    ['us-nc-091', 1926],
    ['us-or-021', 1927],
    ['us-ar-009', 1928],
    ['us-tx-433', 1929],
    ['us-tx-253', 1930],
    ['us-tx-083', 1931],
    ['us-tx-185', 1932],
    ['us-mi-157', 1933],
    ['us-mi-049', 1934],
    ['us-la-007', 1935],
    ['us-la-093', 1936],
    ['us-la-005', 1937],
    ['us-ms-013', 1938],
    ['us-mt-055', 1939],
    ['us-mt-033', 1940],
    ['us-mt-017', 1941],
    ['us-il-141', 1942],
    ['us-nd-077', 1943],
    ['us-sd-109', 1944],
    ['us-wi-095', 1945],
    ['us-ca-015', 1946],
    ['us-ok-127', 1947],
    ['us-wa-061', 1948],
    ['us-wa-007', 1949],
    ['us-ny-105', 1950],
    ['us-nc-149', 1951],
    ['us-nc-163', 1952],
    ['us-nc-085', 1953],
    ['us-nc-105', 1954],
    ['us-in-159', 1955],
    ['us-in-067', 1956],
    ['us-ks-027', 1957],
    ['us-ok-145', 1958],
    ['us-ok-097', 1959],
    ['us-ar-003', 1960],
    ['us-ar-043', 1961],
    ['us-ar-041', 1962],
    ['us-ar-079', 1963],
    ['us-tn-135', 1964],
    ['us-mn-163', 1965],
    ['us-va-740', 1966],
    ['us-tx-235', 1967],
    ['us-mo-025', 1968],
    ['us-mo-049', 1969],
    ['us-fl-007', 1970],
    ['us-fl-107', 1971],
    ['us-fl-083', 1972],
    ['us-ky-163', 1973],
    ['us-ne-161', 1974],
    ['us-ne-075', 1975],
    ['us-id-071', 1976],
    ['us-id-035', 1977],
    ['us-mn-035', 1978],
    ['us-az-021', 1979],
    ['us-az-009', 1980],
    ['us-ar-123', 1981],
    ['us-ks-183', 1982],
    ['us-fl-065', 1983],
    ['us-ms-037', 1984],
    ['us-la-037', 1985],
    ['us-la-091', 1986],
    ['us-ar-097', 1987],
    ['us-mi-025', 1988],
    ['us-mi-077', 1989],
    ['us-mi-015', 1990],
    ['us-al-029', 1991],
    ['us-ca-089', 1992],
    ['us-ia-153', 1993],
    ['us-ia-049', 1994],
    ['us-ga-183', 1995],
    ['us-tn-187', 1996],
    ['us-wa-003', 1997],
    ['us-wa-023', 1998],
    ['us-mn-155', 1999],
    ['us-mn-167', 2000],
    ['us-mn-051', 2001],
    ['us-il-017', 2002],
    ['us-ga-247', 2003],
    ['us-ok-049', 2004],
    ['us-la-031', 2005],
    ['us-ca-019', 2006],
    ['us-ga-147', 2007],
    ['us-ga-105', 2008],
    ['us-al-005', 2009],
    ['us-al-109', 2010],
    ['us-al-011', 2011],
    ['us-nc-017', 2012],
    ['us-tn-035', 2013],
    ['us-tn-129', 2014],
    ['us-mi-137', 2015],
    ['us-ia-161', 2016],
    ['us-ut-041', 2017],
    ['us-ut-015', 2018],
    ['us-mt-083', 2019],
    ['us-mt-109', 2020],
    ['us-nm-053', 2021],
    ['us-ok-021', 2022],
    ['us-mn-173', 2023],
    ['us-tx-231', 2024],
    ['us-tx-223', 2025],
    ['us-tx-467', 2026],
    ['us-tx-353', 2027],
    ['us-tx-499', 2028],
    ['us-ks-019', 2029],
    ['us-nc-039', 2030],
    ['us-ga-291', 2031],
    ['us-ga-111', 2032],
    ['us-ky-195', 2033],
    ['us-fl-041', 2034],
    ['us-nc-035', 2035],
    ['us-ky-025', 2036],
    ['us-mo-181', 2037],
    ['us-il-179', 2038],
    ['us-tx-109', 2039],
    ['us-ar-135', 2040],
    ['us-nc-177', 2041],
    ['us-mn-131', 2042],
    ['us-mn-161', 2043],
    ['us-mn-139', 2044],
    ['us-mn-019', 2045],
    ['us-mo-219', 2046],
    ['us-ky-227', 2047],
    ['us-la-017', 2048],
    ['us-ia-127', 2049],
    ['us-ia-083', 2050],
    ['us-ia-169', 2051],
    ['us-ok-141', 2052],
    ['us-ok-031', 2053],
    ['us-nc-113', 2054],
    ['us-wv-063', 2055],
    ['us-in-155', 2056],
    ['us-al-061', 2057],
    ['us-il-155', 2058],
    ['us-il-099', 2059],
    ['us-ia-055', 2060],
    ['us-ia-105', 2061],
    ['us-ks-127', 2062],
    ['us-id-015', 2063],
    ['us-nc-143', 2064],
    ['us-ga-101', 2065],
    ['us-fl-047', 2066],
    ['us-ms-045', 2067],
    ['us-ga-315', 2068],
    ['us-la-081', 2069],
    ['us-tn-093', 2070],
    ['us-tn-089', 2071],
    ['us-ks-209', 2072],
    ['us-ga-281', 2073],
    ['us-ms-129', 2074],
    ['us-ms-061', 2075],
    ['us-id-075', 2076],
    ['us-id-079', 2077],
    ['us-ia-067', 2078],
    ['us-ky-145', 2079],
    ['us-ky-225', 2080],
    ['us-ky-055', 2081],
    ['us-sd-125', 2082],
    ['us-tn-029', 2083],
    ['us-tn-067', 2084],
    ['us-sc-089', 2085],
    ['us-sc-067', 2086],
    ['us-la-117', 2087],
    ['us-mo-207', 2088],
    ['us-ga-093', 2089],
    ['us-mn-145', 2090],
    ['us-mn-009', 2091],
    ['us-ne-155', 2092],
    ['us-mn-141', 2093],
    ['us-tn-025', 2094],
    ['us-tx-337', 2095],
    ['us-tx-049', 2096],
    ['us-tx-333', 2097],
    ['us-wi-101', 2098],
    ['us-wi-059', 2099],
    ['us-wi-009', 2100],
    ['us-ok-103', 2101],
    ['us-ok-083', 2102],
    ['us-ok-121', 2103],
    ['us-wv-017', 2104],
    ['us-wv-087', 2105],
    ['us-wv-105', 2106],
    ['us-me-017', 2107],
    ['us-nh-003', 2108],
    ['us-wv-095', 2109],
    ['us-mn-079', 2110],
    ['us-wa-037', 2111],
    ['us-wa-017', 2112],
    ['us-wi-133', 2113],
    ['us-ks-151', 2114],
    ['us-al-045', 2115],
    ['us-al-069', 2116],
    ['us-al-067', 2117],
    ['us-ca-109', 2118],
    ['us-ca-039', 2119],
    ['us-mo-075', 2120],
    ['us-ne-009', 2121],
    ['us-ut-009', 2122],
    ['us-nc-071', 2123],
    ['us-nm-019', 2124],
    ['us-mt-027', 2125],
    ['us-mt-037', 2126],
    ['us-nc-195', 2127],
    ['us-nc-101', 2128],
    ['us-ca-085', 2129],
    ['us-il-183', 2130],
    ['us-mo-189', 2131],
    ['us-ca-103', 2132],
    ['us-ca-021', 2133],
    ['us-tx-151', 2134],
    ['us-la-085', 2135],
    ['us-nc-015', 2136],
    ['us-tn-143', 2137],
    ['us-nc-079', 2138],
    ['us-ky-131', 2139],
    ['us-il-123', 2140],
    ['us-tx-081', 2141],
    ['us-tn-133', 2142],
    ['us-mi-063', 2143],
    ['us-mi-129', 2144],
    ['us-mi-069', 2145],
    ['us-ga-069', 2146],
    ['us-ga-299', 2147],
    ['us-la-103', 2148],
    ['us-oh-057', 2149],
    ['us-mn-137', 2150],
    ['us-oh-043', 2151],
    ['us-oh-093', 2152],
    ['us-ia-015', 2153],
    ['us-tx-373', 2154],
    ['us-sd-027', 2155],
    ['us-ne-051', 2156],
    ['us-ne-043', 2157],
    ['us-sd-127', 2158],
    ['us-sc-037', 2159],
    ['us-va-133', 2160],
    ['us-wi-015', 2161],
    ['us-mi-067', 2162],
    ['us-tn-095', 2163],
    ['us-tn-131', 2164],
    ['us-ky-105', 2165],
    ['us-ky-035', 2166],
    ['us-va-015', 2167],
    ['us-va-003', 2168],
    ['us-va-029', 2169],
    ['us-tn-147', 2170],
    ['us-va-043', 2171],
    ['us-ky-109', 2172],
    ['us-sc-073', 2173],
    ['us-la-033', 2174],
    ['us-mi-081', 2175],
    ['us-nc-147', 2176],
    ['us-mt-009', 2177],
    ['us-mt-111', 2178],
    ['us-ne-039', 2179],
    ['us-ga-001', 2180],
    ['us-ia-099', 2181],
    ['us-ms-153', 2182],
    ['us-ms-023', 2183],
    ['us-tx-155', 2184],
    ['us-tx-275', 2185],
    ['us-fl-105', 2186],
    ['us-fl-097', 2187],
    ['us-al-087', 2188],
    ['us-al-123', 2189],
    ['us-or-043', 2190],
    ['us-or-017', 2191],
    ['us-ia-155', 2192],
    ['us-ia-145', 2193],
    ['us-mo-229', 2194],
    ['us-ny-107', 2195],
    ['us-tx-287', 2196],
    ['us-ga-121', 2197],
    ['us-tx-073', 2198],
    ['us-mo-007', 2199],
    ['us-sc-029', 2200],
    ['us-ar-055', 2201],
    ['us-tx-191', 2202],
    ['us-wi-013', 2203],
    ['us-wi-129', 2204],
    ['us-nc-141', 2205],
    ['us-nc-061', 2206],
    ['us-tx-221', 2207],
    ['us-sd-119', 2208],
    ['us-nv-031', 2209],
    ['us-ky-007', 2210],
    ['us-tx-009', 2211],
    ['us-tx-503', 2212],
    ['us-tx-023', 2213],
    ['us-tx-405', 2214],
    ['us-nv-029', 2215],
    ['us-ct-001', 2216],
    ['us-ca-009', 2217],
    ['us-ca-077', 2218],
    ['us-ms-159', 2219],
    ['us-ky-167', 2220],
    ['us-ne-183', 2221],
    ['us-ky-135', 2222],
    ['us-mn-149', 2223],
    ['us-ms-053', 2224],
    ['us-ms-051', 2225],
    ['us-ks-017', 2226],
    ['us-ky-143', 2227],
    ['us-ky-033', 2228],
    ['us-ky-221', 2229],
    ['us-sd-003', 2230],
    ['us-ne-061', 2231],
    ['us-ks-037', 2232],
    ['us-mn-023', 2233],
    ['us-sc-049', 2234],
    ['us-sc-013', 2235],
    ['us-id-043', 2236],
    ['us-va-053', 2237],
    ['us-va-081', 2238],
    ['us-fl-111', 2239],
    ['us-fl-061', 2240],
    ['us-ny-073', 2241],
    ['us-ny-055', 2242],
    ['us-sd-083', 2243],
    ['us-wi-017', 2244],
    ['us-ky-157', 2245],
    ['us-ga-065', 2246],
    ['us-ga-185', 2247],
    ['us-mi-099', 2248],
    ['us-tx-331', 2249],
    ['us-ky-087', 2250],
    ['us-ky-099', 2251],
    ['us-ky-137', 2252],
    ['us-va-710', 2253],
    ['us-va-155', 2254],
    ['us-nc-179', 2255],
    ['us-nd-061', 2256],
    ['us-nd-053', 2257],
    ['us-fl-091', 2258],
    ['us-ca-001', 2259],
    ['us-il-013', 2260],
    ['us-in-085', 2261],
    ['us-ms-103', 2262],
    ['us-mt-045', 2263],
    ['us-mt-107', 2264],
    ['us-nc-121', 2265],
    ['us-or-053', 2266],
    ['us-or-041', 2267],
    ['us-or-065', 2268],
    ['us-or-047', 2269],
    ['us-ia-173', 2270],
    ['us-oh-039', 2271],
    ['us-wv-039', 2272],
    ['us-wv-019', 2273],
    ['us-wv-025', 2274],
    ['us-il-121', 2275],
    ['us-va-051', 2276],
    ['us-va-007', 2277],
    ['us-ga-243', 2278],
    ['us-nm-029', 2279],
    ['us-tx-355', 2280],
    ['us-tx-409', 2281],
    ['us-wy-045', 2282],
    ['us-sd-081', 2283],
    ['us-sd-093', 2284],
    ['us-sd-033', 2285],
    ['us-al-089', 2286],
    ['us-tn-189', 2287],
    ['us-tx-063', 2288],
    ['us-tx-459', 2289],
    ['us-ok-067', 2290],
    ['us-wv-085', 2291],
    ['us-wv-021', 2292],
    ['us-la-045', 2293],
    ['us-tx-349', 2294],
    ['us-tx-161', 2295],
    ['us-pa-087', 2296],
    ['us-tx-399', 2297],
    ['us-tx-465', 2298],
    ['us-ks-193', 2299],
    ['us-ga-161', 2300],
    ['us-ar-145', 2301],
    ['us-ms-083', 2302],
    ['us-mo-053', 2303],
    ['us-nm-009', 2304],
    ['us-tx-369', 2305],
    ['us-sd-011', 2306],
    ['us-ms-027', 2307],
    ['us-ia-057', 2308],
    ['us-ia-087', 2309],
    ['us-ia-163', 2310],
    ['us-ks-143', 2311],
    ['us-fl-023', 2312],
    ['us-ny-095', 2313],
    ['us-ok-117', 2314],
    ['us-ny-007', 2315],
    ['us-oh-009', 2316],
    ['us-wv-083', 2317],
    ['us-ca-057', 2318],
    ['us-mn-017', 2319],
    ['us-ky-199', 2320],
    ['us-nc-065', 2321],
    ['us-tx-241', 2322],
    ['us-ut-031', 2323],
    ['us-ut-017', 2324],
    ['us-ut-001', 2325],
    ['us-tn-011', 2326],
    ['us-tn-139', 2327],
    ['us-ga-213', 2328],
    ['us-mi-107', 2329],
    ['us-mi-117', 2330],
    ['us-ny-083', 2331],
    ['us-ga-017', 2332],
    ['us-ga-271', 2333],
    ['us-ar-077', 2334],
    ['us-fl-003', 2335],
    ['us-fl-031', 2336],
    ['us-ga-173', 2337],
    ['us-al-015', 2338],
    ['us-ky-057', 2339],
    ['us-ms-107', 2340],
    ['us-ga-239', 2341],
    ['us-tx-199', 2342],
    ['us-ct-005', 2343],
    ['us-oh-143', 2344],
    ['us-oh-147', 2345],
    ['us-il-153', 2346],
    ['us-ok-107', 2347],
    ['us-mi-145', 2348],
    ['us-vt-015', 2349],
    ['us-vt-005', 2350],
    ['us-il-119', 2351],
    ['us-tx-085', 2352],
    ['us-ne-053', 2353],
    ['us-fl-115', 2354],
    ['us-pa-091', 2355],
    ['us-id-061', 2356],
    ['us-nj-037', 2357],
    ['us-nj-027', 2358],
    ['us-mo-211', 2359],
    ['us-ne-029', 2360],
    ['us-oh-113', 2361],
    ['us-tx-447', 2362],
    ['us-ga-059', 2363],
    ['us-ga-221', 2364],
    ['us-mi-017', 2365],
    ['us-tn-157', 2366],
    ['us-ca-113', 2367],
    ['us-ca-007', 2368],
    ['us-tx-021', 2369],
    ['us-wa-039', 2370],
    ['us-wa-005', 2371],
    ['us-wa-071', 2372],
    ['us-ks-205', 2373],
    ['us-ks-049', 2374],
    ['us-ks-125', 2375],
    ['us-la-043', 2376],
    ['us-la-069', 2377],
    ['us-mi-011', 2378],
    ['us-ia-111', 2379],
    ['us-ks-141', 2380],
    ['us-ks-105', 2381],
    ['us-ks-053', 2382],
    ['us-ny-099', 2383],
    ['us-ny-123', 2384],
    ['us-ky-125', 2385],
    ['us-tn-121', 2386],
    ['us-mo-213', 2387],
    ['us-co-047', 2388],
    ['us-ok-087', 2389],
    ['us-wv-031', 2390],
    ['us-va-171', 2391],
    ['us-va-139', 2392],
    ['us-ok-061', 2393],
    ['us-wv-089', 2394],
    ['us-nj-013', 2395],
    ['us-mo-115', 2396],
    ['us-ga-209', 2397],
    ['us-or-055', 2398],
    ['us-ga-133', 2399],
    ['us-sd-017', 2400],
    ['us-sd-085', 2401],
    ['us-sd-053', 2402],
    ['us-tx-135', 2403],
    ['us-tx-475', 2404],
    ['us-tx-293', 2405],
    ['us-wv-037', 2406],
    ['us-wv-003', 2407],
    ['us-mt-067', 2408],
    ['us-tx-121', 2409],
    ['us-nc-199', 2410],
    ['us-tx-387', 2411],
    ['us-ga-301', 2412],
    ['us-tx-197', 2413],
    ['us-mo-099', 2414],
    ['us-mo-186', 2415],
    ['us-ky-085', 2416],
    ['us-ga-149', 2417],
    ['us-al-079', 2418],
    ['us-al-043', 2419],
    ['us-ms-093', 2420],
    ['us-ks-025', 2421],
    ['us-ks-081', 2422],
    ['us-la-013', 2423],
    ['us-ga-295', 2424],
    ['us-ia-095', 2425],
    ['us-ne-019', 2426],
    ['us-ne-093', 2427],
    ['us-tx-357', 2428],
    ['us-wa-015', 2429],
    ['us-tx-297', 2430],
    ['us-mo-135', 2431],
    ['us-ok-043', 2432],
    ['us-nc-023', 2433],
    ['us-nc-191', 2434],
    ['us-ar-061', 2435],
    ['us-nc-059', 2436],
    ['us-nc-111', 2437],
    ['us-ar-099', 2438],
    ['us-co-121', 2439],
    ['us-tx-385', 2440],
    ['us-oh-011', 2441],
    ['us-oh-107', 2442],
    ['us-in-001', 2443],
    ['us-ks-169', 2444],
    ['us-ky-153', 2445],
    ['us-tx-017', 2446],
    ['us-mo-027', 2447],
    ['us-wy-029', 2448],
    ['us-pa-093', 2449],
    ['us-ny-081', 2450],
    ['us-mi-031', 2451],
    ['us-mi-047', 2452],
    ['us-mi-051', 2453],
    ['us-mi-111', 2454],
    ['us-ky-173', 2455],
    ['us-sc-023', 2456],
    ['us-ky-123', 2457],
    ['us-ia-193', 2458],
    ['us-ia-093', 2459],
    ['us-fl-123', 2460],
    ['us-mo-201', 2461],
    ['us-il-003', 2462],
    ['us-il-181', 2463],
    ['us-mo-113', 2464],
    ['us-ga-051', 2465],
    ['us-fl-125', 2466],
    ['us-ny-037', 2467],
    ['us-ia-141', 2468],
    ['us-ia-041', 2469],
    ['us-sd-107', 2470],
    ['us-me-005', 2471],
    ['us-ks-095', 2472],
    ['us-ga-241', 2473],
    ['us-wv-097', 2474],
    ['us-ar-119', 2475],
    ['us-ia-047', 2476],
    ['us-oh-055', 2477],
    ['us-ny-117', 2478],
    ['us-va-163', 2479],
    ['us-nd-095', 2480],
    ['us-pa-045', 2481],
    ['us-vt-021', 2482],
    ['us-vt-003', 2483],
    ['us-fl-019', 2484],
    ['us-nc-183', 2485],
    ['us-oh-061', 2486],
    ['us-az-015', 2487],
    ['us-ia-019', 2488],
    ['us-sd-047', 2489],
    ['us-mn-069', 2490],
    ['us-ga-219', 2491],
    ['us-tx-493', 2492],
    ['us-fl-079', 2493],
    ['us-in-153', 2494],
    ['us-ga-027', 2495],
    ['us-ks-185', 2496],
    ['us-ks-159', 2497],
    ['us-mi-115', 2498],
    ['us-ky-139', 2499],
    ['us-tn-103', 2500],
    ['us-ky-103', 2501],
    ['us-ky-211', 2502],
    ['us-pa-001', 2503],
    ['us-sc-009', 2504],
    ['us-ut-047', 2505],
    ['us-ut-049', 2506],
    ['us-mo-141', 2507],
    ['us-mo-015', 2508],
    ['us-ia-143', 2509],
    ['us-in-079', 2510],
    ['us-in-143', 2511],
    ['us-id-083', 2512],
    ['us-il-165', 2513],
    ['us-sc-061', 2514],
    ['us-wy-019', 2515],
    ['us-mi-165', 2516],
    ['us-mi-055', 2517],
    ['us-mi-079', 2518],
    ['us-la-003', 2519],
    ['us-la-011', 2520],
    ['us-sd-069', 2521],
    ['us-sc-027', 2522],
    ['us-mt-095', 2523],
    ['us-ky-219', 2524],
    ['us-tn-183', 2525],
    ['us-tn-017', 2526],
    ['us-mo-077', 2527],
    ['us-ne-159', 2528],
    ['us-ny-015', 2529],
    ['us-ca-043', 2530],
    ['us-nj-041', 2531],
    ['us-al-103', 2532],
    ['us-ky-027', 2533],
    ['us-ms-043', 2534],
    ['us-ky-213', 2535],
    ['us-vt-013', 2536],
    ['us-va-125', 2537],
    ['us-fl-093', 2538],
    ['us-ar-053', 2539],
    ['us-ga-175', 2540],
    ['us-ar-007', 2541],
    ['us-ar-143', 2542],
    ['us-fl-127', 2543],
    ['us-fl-009', 2544],
    ['us-va-135', 2545],
    ['us-ny-021', 2546],
    ['us-ok-023', 2547],
    ['us-ks-041', 2548],
    ['us-tx-427', 2549],
    ['us-ga-103', 2550],
    ['us-ok-129', 2551],
    ['us-wi-103', 2552],
    ['us-tx-079', 2553],
    ['us-al-075', 2554],
    ['us-tn-117', 2555],
    ['us-ar-087', 2556],
    ['us-pa-055', 2557],
    ['us-pa-079', 2558],
    ['us-mi-039', 2559],
    ['us-al-113', 2560],
    ['us-va-065', 2561],
    ['us-il-097', 2562],
    ['us-ga-195', 2563],
    ['us-ar-039', 2564],
    ['us-co-057', 2565],
    ['us-id-001', 2566],
    ['us-wi-141', 2567],
    ['us-ny-013', 2568],
    ['us-tn-091', 2569],
    ['us-nc-009', 2570],
    ['us-va-195', 2571],
    ['us-mo-035', 2572],
    ['us-ok-013', 2573],
    ['us-ia-033', 2574],
    ['us-ky-205', 2575],
    ['us-mo-031', 2576],
    ['us-mo-133', 2577],
    ['us-ky-075', 2578],
    ['us-mo-143', 2579],
    ['us-ar-139', 2580],
    ['us-ar-013', 2581],
    ['us-ar-025', 2582],
    ['us-la-073', 2583],
    ['us-ia-007', 2584],
    ['us-mo-197', 2585],
    ['us-al-059', 2586],
    ['us-ky-041', 2587],
    ['us-tx-267', 2588],
    ['us-tx-095', 2589],
    ['us-tx-441', 2590],
    ['us-tx-307', 2591],
    ['us-tx-217', 2592],
    ['us-ks-055', 2593],
    ['us-sc-039', 2594],
    ['us-mn-091', 2595],
    ['us-tn-155', 2596],
    ['us-tx-263', 2597],
    ['us-mt-099', 2598],
    ['us-ne-069', 2599],
    ['us-il-117', 2600],
    ['us-oh-141', 2601],
    ['us-il-195', 2602],
    ['us-tx-141', 2603],
    ['us-tx-229', 2604],
    ['us-tx-301', 2605],
    ['us-oh-167', 2606],
    ['us-mo-091', 2607],
    ['us-ks-047', 2608],
    ['us-in-061', 2609],
    ['us-in-043', 2610],
    ['us-ky-111', 2611],
    ['us-ky-029', 2612],
    ['us-in-019', 2613],
    ['us-il-129', 2614],
    ['us-wi-139', 2615],
    ['us-ks-079', 2616],
    ['us-ks-155', 2617],
    ['us-wv-091', 2618],
    ['us-ky-093', 2619],
    ['us-sd-057', 2620],
    ['us-wi-069', 2621],
    ['us-ny-033', 2622],
    ['us-wv-081', 2623],
    ['us-ca-065', 2624],
    ['us-ca-059', 2625],
    ['us-ca-037', 2626],
    ['us-az-023', 2627],
    ['us-il-101', 2628],
    ['us-in-125', 2629],
    ['us-ky-107', 2630],
    ['us-ky-047', 2631],
    ['us-mi-045', 2632],
    ['us-ms-157', 2633],
    ['us-ms-079', 2634],
    ['us-ms-025', 2635],
    ['us-in-029', 2636],
    ['us-in-175', 2637],
    ['us-la-125', 2638],
    ['us-fl-063', 2639],
    ['us-ga-253', 2640],
    ['us-va-115', 2641],
    ['us-tx-283', 2642],
    ['us-mt-075', 2643],
    ['us-wi-091', 2644],
    ['us-mn-157', 2645],
    ['us-mo-121', 2646],
    ['us-tx-279', 2647],
    ['us-ks-097', 2648],
    ['us-tx-015', 2649],
    ['us-mo-061', 2650],
    ['us-ga-087', 2651],
    ['us-ga-201', 2652],
    ['us-mi-101', 2653],
    ['us-tx-195', 2654],
    ['us-tx-233', 2655],
    ['us-ks-129', 2656],
    ['us-ok-139', 2657],
    ['us-ok-153', 2658],
    ['us-ia-003', 2659],
    ['us-ca-101', 2660],
    ['us-pa-105', 2661],
    ['us-ks-077', 2662],
    ['us-ia-133', 2663],
    ['us-ny-017', 2664],
    ['us-il-143', 2665],
    ['us-ia-091', 2666],
    ['us-pa-003', 2667],
    ['us-mo-205', 2668],
    ['us-oh-097', 2669],
    ['us-ks-201', 2670],
    ['us-id-087', 2671],
    ['us-mi-113', 2672],
    ['us-nd-093', 2673],
    ['us-mo-203', 2674],
    ['us-tx-423', 2675],
    ['us-ok-143', 2676],
    ['us-ok-091', 2677],
    ['us-tx-395', 2678],
    ['us-tx-171', 2679],
    ['us-mi-125', 2680],
    ['us-nd-033', 2681],
    ['us-wy-027', 2682],
    ['us-wa-025', 2683],
    ['us-mn-129', 2684],
    ['us-fl-001', 2685],
    ['us-ne-091', 2686],
    ['us-ut-007', 2687],
    ['us-pa-017', 2688],
    ['us-nj-019', 2689],
    ['us-sd-059', 2690],
    ['us-pa-081', 2691],
    ['us-id-057', 2692],
    ['us-ga-237', 2693],
    ['us-va-640', 2694],
    ['us-nm-059', 2695],
    ['us-ok-025', 2696],
    ['us-tx-111', 2697],
    ['us-tx-269', 2698],
    ['us-tx-451', 2699],
    ['us-fl-049', 2700],
    ['us-ky-037', 2701],
    ['us-oh-025', 2702],
    ['us-tx-501', 2703],
    ['us-or-071', 2704],
    ['us-ia-115', 2705],
    ['us-oh-121', 2706],
    ['us-oh-119', 2707],
    ['us-pa-075', 2708],
    ['us-mn-087', 2709],
    ['us-nj-031', 2710],
    ['us-tx-033', 2711],
    ['us-ok-073', 2712],
    ['us-tx-051', 2713],
    ['us-ms-133', 2714],
    ['us-mn-053', 2715],
    ['us-pa-027', 2716],
    ['us-tx-025', 2717],
    ['us-ia-171', 2718],
    ['us-ne-147', 2719],
    ['us-il-021', 2720],
    ['us-fl-089', 2721],
    ['us-ga-007', 2722],
    ['us-ga-205', 2723],
    ['us-ga-275', 2724],
    ['us-ky-005', 2725],
    ['us-ky-185', 2726],
    ['us-nd-065', 2727],
    ['us-tn-109', 2728],
    ['us-vt-007', 2729],
    ['us-ia-139', 2730],
    ['us-ky-223', 2731],
    ['us-ga-055', 2732],
    ['us-sc-051', 2733],
    ['us-ks-131', 2734],
    ['us-ne-083', 2735],
    ['us-ks-179', 2736],
    ['us-ne-065', 2737],
    ['us-ky-009', 2738],
    ['us-ca-005', 2739],
    ['us-tn-049', 2740],
    ['us-mi-023', 2741],
    ['us-va-031', 2742],
    ['us-oh-105', 2743],
    ['us-ok-053', 2744],
    ['us-sd-115', 2745],
    ['us-tx-455', 2746],
    ['us-ky-147', 2747],
    ['us-ky-191', 2748],
    ['us-ky-215', 2749],
    ['us-ne-175', 2750],
    ['us-tx-069', 2751],
    ['us-md-043', 2752],
    ['us-va-680', 2753],
    ['us-ar-125', 2754],
    ['us-wv-053', 2755],
    ['us-ms-063', 2756],
    ['us-nd-079', 2757],
    ['us-mt-103', 2758],
    ['us-ky-049', 2759],
    ['us-mi-005', 2760],
    ['us-mn-015', 2761],
    ['us-va-109', 2762],
    ['us-in-145', 2763],
    ['us-oh-049', 2764],
    ['us-ks-093', 2765],
    ['us-ia-117', 2766],
    ['us-ky-239', 2767],
    ['us-sc-053', 2768],
    ['us-sc-065', 2769],
    ['us-sc-087', 2770],
    ['us-mn-103', 2771],
    ['us-wa-011', 2772],
    ['us-wa-059', 2773],
    ['us-wi-051', 2774],
    ['us-la-123', 2775],
    ['us-fl-077', 2776],
    ['us-ks-083', 2777],
    ['us-ks-069', 2778],
    ['us-ks-057', 2779],
    ['us-ks-119', 2780],
    ['us-ms-001', 2781],
    ['us-ms-057', 2782],
    ['us-ky-073', 2783],
    ['us-ga-217', 2784],
    ['us-sd-049', 2785],
    ['us-mi-061', 2786],
    ['us-tn-127', 2787],
    ['us-la-029', 2788],
    ['us-la-063', 2789],
    ['us-ma-009', 2790],
    ['us-sc-079', 2791],
    ['us-sc-017', 2792],
    ['us-wv-009', 2793],
    ['us-wv-013', 2794],
    ['us-va-021', 2795],
    ['us-wv-043', 2796],
    ['us-ga-131', 2797],
    ['us-co-021', 2798],
    ['us-az-005', 2799],
    ['us-ut-055', 2800],
    ['us-in-133', 2801],
    ['us-id-047', 2802],
    ['us-tn-167', 2803],
    ['us-tn-075', 2804],
    ['us-tn-097', 2805],
    ['us-mo-171', 2806],
    ['us-nd-059', 2807],
    ['us-nc-041', 2808],
    ['us-nc-021', 2809],
    ['us-nc-089', 2810],
    ['us-ca-063', 2811],
    ['us-ar-115', 2812],
    ['us-tx-183', 2813],
    ['us-ar-089', 2814],
    ['us-mo-187', 2815],
    ['us-co-025', 2816],
    ['us-tx-487', 2817],
    ['us-or-015', 2818],
    ['us-ut-051', 2819],
    ['us-ar-011', 2820],
    ['us-tx-193', 2821],
    ['us-md-011', 2822],
    ['us-mn-041', 2823],
    ['us-mt-077', 2824],
    ['us-mt-043', 2825],
    ['us-mt-007', 2826],
    ['us-mt-023', 2827],
    ['us-mt-039', 2828],
    ['us-mt-001', 2829],
    ['us-tn-001', 2830],
    ['us-oh-077', 2831],
    ['us-ga-005', 2832],
    ['us-de-001', 2833],
    ['us-il-095', 2834],
    ['us-tn-015', 2835],
    ['us-me-007', 2836],
    ['us-ky-165', 2837],
    ['us-sd-089', 2838],
    ['us-co-051', 2839],
    ['us-mn-151', 2840],
    ['us-ga-267', 2841],
    ['us-tx-429', 2842],
    ['us-nc-117', 2843],
    ['us-mt-081', 2844],
    ['us-ga-317', 2845],
    ['us-ca-081', 2846],
    ['us-oh-159', 2847],
    ['us-oh-035', 2848],
    ['us-oh-153', 2849],
    ['us-oh-103', 2850],
    ['us-tx-285', 2851],
    ['us-ok-105', 2852],
    ['us-ga-125', 2853],
    ['us-sd-113', 2854],
    ['us-ks-117', 2855],
    ['us-la-065', 2856],
    ['us-ga-313', 2857],
    ['us-ks-203', 2858],
    ['us-ne-027', 2859],
    ['us-il-111', 2860],
    ['us-mt-069', 2861],
    ['us-mo-033', 2862],
    ['us-wa-021', 2863],
    ['us-wa-075', 2864],
    ['us-ne-169', 2865],
    ['us-tn-033', 2866],
    ['us-tn-053', 2867],
    ['us-ia-079', 2868],
    ['us-ga-255', 2869],
    ['us-ks-107', 2870],
    ['us-mo-217', 2871],
    ['us-in-121', 2872],
    ['us-ia-069', 2873],
    ['us-ga-035', 2874],
    ['us-tx-035', 2875],
    ['us-nd-069', 2876],
    ['us-il-015', 2877],
    ['us-or-031', 2878],
    ['us-al-051', 2879],
    ['us-ga-187', 2880],
    ['us-oh-083', 2881],
    ['us-tx-175', 2882],
    ['us-tx-065', 2883],
    ['us-tx-379', 2884],
    ['us-ks-067', 2885],
    ['us-va-105', 2886],
    ['us-nm-051', 2887],
    ['us-ne-123', 2888],
    ['us-ar-109', 2889],
    ['us-co-077', 2890],
    ['us-mo-065', 2891],
    ['us-va-185', 2892],
    ['us-pa-103', 2893],
    ['us-pa-115', 2894],
    ['us-tx-343', 2895],
    ['us-pa-125', 2896],
    ['us-vt-001', 2897],
    ['us-vt-023', 2898],
    ['us-ks-103', 2899],
    ['us-ks-087', 2900],
    ['us-mi-121', 2901],
    ['us-ms-049', 2902],
    ['us-tx-281', 2903],
    ['us-va-107', 2904],
    ['us-mo-185', 2905],
    ['us-mo-129', 2906],
    ['us-nc-127', 2907],
    ['us-mo-159', 2908],
    ['us-va-047', 2909],
    ['us-wv-069', 2910],
    ['us-wv-065', 2911],
    ['us-va-165', 2912],
    ['us-in-183', 2913],
    ['us-ca-093', 2914],
    ['us-sd-037', 2915],
    ['us-la-071', 2916],
    ['us-al-127', 2917],
    ['us-mo-063', 2918],
    ['us-fl-039', 2919],
    ['us-ga-083', 2920],
    ['us-va-187', 2921],
    ['us-tn-113', 2922],
    ['us-oh-089', 2923],
    ['us-al-101', 2924],
    ['us-al-041', 2925],
    ['us-ky-155', 2926],
    ['us-ms-067', 2927],
    ['us-wi-021', 2928],
    ['us-mn-029', 2929],
    ['us-ks-163', 2930],
    ['us-in-041', 2931],
    ['us-ne-079', 2932],
    ['us-ne-081', 2933],
    ['us-ne-115', 2934],
    ['us-al-007', 2935],
    ['us-al-021', 2936],
    ['us-ms-075', 2937],
    ['us-mn-011', 2938],
    ['us-mn-013', 2939],
    ['us-va-091', 2940],
    ['us-mi-041', 2941],
    ['us-ar-131', 2942],
    ['us-ga-207', 2943],
    ['us-mo-069', 2944],
    ['us-ga-117', 2945],
    ['us-tx-249', 2946],
    ['us-mi-073', 2947],
    ['us-tx-421', 2948],
    ['us-tx-341', 2949],
    ['us-wy-035', 2950],
    ['us-ky-069', 2951],
    ['us-wi-019', 2952],
    ['us-sc-091', 2953],
    ['us-pa-119', 2954],
    ['us-va-570', 2955],
    ['us-va-670', 2956],
    ['us-va-730', 2957],
    ['us-la-001', 2958],
    ['us-sd-123', 2959],
    ['us-ia-077', 2960],
    ['us-ia-183', 2961],
    ['us-sd-111', 2962],
    ['us-ok-051', 2963],
    ['us-wi-109', 2964],
    ['us-pa-121', 2965],
    ['us-tx-479', 2966],
    ['us-nc-123', 2967],
    ['us-me-003', 2968],
    ['us-ms-065', 2969],
    ['us-tx-031', 2970],
    ['us-va-025', 2971],
    ['us-tn-045', 2972],
    ['us-ar-075', 2973],
    ['us-nd-027', 2974],
    ['us-wi-057', 2975],
    ['us-wi-081', 2976],
    ['us-tn-151', 2977],
    ['us-sd-031', 2978],
    ['us-nc-181', 2979],
    ['us-sc-011', 2980],
    ['us-tn-039', 2981],
    ['us-al-055', 2982],
    ['us-id-025', 2983],
    ['us-tn-119', 2984],
    ['us-il-077', 2985],
    ['us-sc-043', 2986],
    ['us-ut-045', 2987],
    ['us-va-650', 2988],
    ['us-wi-011', 2989],
    ['us-va-069', 2990],
    ['us-wv-041', 2991],
    ['us-mn-119', 2992],
    ['us-mn-127', 2993],
    ['us-tn-047', 2994],
    ['us-la-089', 2995],
    ['us-ar-111', 2996],
    ['us-fl-045', 2997],
    ['us-tn-063', 2998],
    ['us-tx-351', 2999],
    ['us-tx-219', 3000],
    ['us-nc-175', 3001],
    ['us-nc-171', 3002],
    ['us-al-031', 3003],
    ['us-id-027', 3004],
    ['us-fl-073', 3005],
    ['us-ok-119', 3006],
    ['us-al-039', 3007],
    ['us-ky-045', 3008],
    ['us-ky-169', 3009],
    ['us-ky-207', 3010],
    ['us-me-019', 3011],
    ['us-me-025', 3012],
    ['us-ky-179', 3013],
    ['us-ky-187', 3014],
    ['us-mo-155', 3015],
    ['us-ne-125', 3016],
    ['us-tx-365', 3017],
    ['us-ky-011', 3018],
    ['us-ia-167', 3019],
    ['us-mo-510', 3020],
    ['us-sd-091', 3021],
    ['us-ne-107', 3022],
    ['us-mt-079', 3023],
    ['us-ia-005', 3024],
    ['us-tx-061', 3025],
    ['us-tx-339', 3026],
    ['us-tx-319', 3027],
    ['us-nc-043', 3028],
    ['us-ny-093', 3029],
    ['us-ks-197', 3030],
    ['us-ky-181', 3031],
    ['us-wi-071', 3032],
    ['us-nc-047', 3033],
    ['us-oh-053', 3034],
    ['us-oh-031', 3035],
    ['us-tx-205', 3036],
    ['us-ia-189', 3037],
    ['us-tx-505', 3038],
    ['us-ky-019', 3039],
    ['us-ne-037', 3040],
    ['us-sc-025', 3041],
    ['us-tn-101', 3042],
    ['us-or-067', 3043],
    ['us-va-810', 3044],
    ['us-mt-057', 3045],
    ['us-va-103', 3046],
    ['us-mo-013', 3047],
    ['us-nh-001', 3048],
    ['us-va-159', 3049],
    ['us-ks-173', 3050],
    ['us-tx-453', 3051],
    ['us-fl-057', 3052],
    ['us-nj-021', 3053],
    ['us-pa-129', 3054],
    ['us-ia-029', 3055],
    ['us-ky-115', 3056],
    ['us-ri-003', 3057],
    ['us-vt-019', 3058],
    ['us-ks-085', 3059],
    ['us-la-041', 3060],
    ['us-ms-155', 3061],
    ['us-ma-011', 3062],
    ['us-sd-007', 3063],
    ['us-mt-093', 3064],
    ['us-ar-057', 3065],
    ['us-mo-029', 3066],
    ['us-va-117', 3067],
    ['us-ky-233', 3068],
    ['us-ok-047', 3069],
    ['us-mi-091', 3070],
    ['us-ga-123', 3071],
    ['us-ms-089', 3072],
    ['us-tx-227', 3073],
    ['us-wv-099', 3074],
    ['us-mi-013', 3075],
    ['us-va-760', 3076],
    ['us-ca-079', 3077],
    ['us-tx-001', 3078],
    ['us-nd-071', 3079],
    ['us-co-029', 3080],
    ['us-nm-021', 3081],
    ['us-tx-457', 3082],
    ['us-wa-077', 3083],
    ['us-tx-347', 3084],
    ['us-mt-061', 3085],
    ['us-nm-047', 3086],
    ['us-sd-103', 3087],
    ['us-ia-149', 3088],
    ['us-id-005', 3089],
    ['us-az-025', 3090],
    ['us-az-012', 3091],
    ['us-sd-065', 3092],
    ['us-ok-077', 3093],
    ['us-ny-067', 3094],
    ['us-mn-097', 3095],
    ['us-wa-001', 3096],
    ['us-co-039', 3097],
    ['us-al-073', 3098],
    ['us-nm-061', 3099],
    ['us-mi-083', 3100],
    ['us-or-011', 3101],
    ['us-ca-107', 3102],
    ['us-ca-025', 3103],
    ['us-ks-003', 3104],
    ['us-va-770', 3105],
    ['us-ma-001', 3106],
    ['us-ca-073', 3107],
    ['us-nv-009', 3108],
    ['us-mn-031', 3109],
    ['us-wa-009', 3110],
    ['us-mt-035', 3111],
    ['us-nm-005', 3112],
    ['us-az-007', 3113],
    ['us-me-021', 3114],
    ['us-mt-085', 3115],
    ['us-mt-021', 3116],
    ['us-mn-125', 3117],
    ['us-wy-017', 3118],
    [null, 3119],
    ['us-hi-003', 3120],
    ['us-hi-007', 3121],
    ['us-hi-009', 3122],
    ['us-hi-001', 3123],
    ['us-hi-005', 3124],
    [null, 3125],
    ['us-ak-110', 3126],
    ['us-ak-261', 3127],
    ['us-ak-020', 3128],
    ['us-ak-070', 3129],
    ['us-ak-013', 3130],
    ['us-ak-180', 3131],
    ['us-ak-016', 3132],
    ['us-ak-150', 3133],
    ['us-ak-290', 3134],
    ['us-ak-105', 3135],
    ['us-ak-122', 3136],
    ['us-ak-050', 3137],
    ['us-ak-164', 3138],
    ['us-ak-060', 3139],
    ['us-ak-130', 3140],
    ['us-ak-170', 3141],
    ['us-ak-090', 3142],
    ['us-ak-068', 3143],
    ['us-ak-198', 3144],
    ['us-ak-195', 3145],
    ['us-ak-100', 3146],
    ['us-ak-230', 3147],
    ['us-ak-240', 3148],
    ['us-ak-220', 3149],
    ['us-ak-188', 3150],
    ['us-ak-270', 3151],
    ['us-ak-185', 3152],
    ['us-ak-282', 3153],
    ['us-ak-275', 3154],
    [null, 3155]
  ].map((e) => e[0]);
}
