import browser from 'browser-detect';
import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment as env } from '../../environments/environment';

import {
  authLogin,
  authLogout,
  routeAnimations,
  LocalStorageService,
  selectIsAuthenticated,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme,
  AppState
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions';

declare var google: any;

import Highcharts from 'highcharts/highmaps';
import worldMap from '@highcharts/map-collection/countries/us/us-all-all.topo.json';
const mmHash = require('murmurhash');
//@ts-ignore
import _hckey from './mapdata';

//@ts-ignore
declare var window: any;

interface REC {
  lat: number;
  lng: number;
  color: string;
  icon: any;
}

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  entities = ['accounts', 'crmActivities', 'deals', 'contacts'];
  activeEntity = this.entities[0];
  user = { token: null, orgId: '' };
  aggName = 'count';
  title = 'My first AGM project';
  lat = 51.678418;
  lng = 7.809007;
  hcKeyMap = new Map();
  hckey = _hckey();
  busy = false;
  selectFields = 'geoPoint,annualRevenue';
  classification = ['Natural', 'Quantile', 'EqualInterval', 'Manual'];
  bucketCount = 5;
  activeClassification = this.classification[0];
  manualRanges = '';
  fields = [];
  activeField = 'annualRevenue';

  dataset: Array<REC> = [];

  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = 'assets/logo.png';
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he', 'ar'];
  navigation = [
    { link: 'about', label: 'anms.menu.about' },
    { link: 'feature-list', label: 'anms.menu.features' },
    { link: 'examples', label: 'anms.menu.examples' }
  ];
  navigationSideMenu = [
    ...this.navigation,
    { link: 'settings', label: 'anms.menu.settings' }
  ];

  isAuthenticated$: Observable<boolean> | undefined;
  stickyHeader$: Observable<boolean> | undefined;
  language$: Observable<string> | undefined;
  theme$: Observable<string> | undefined;
  status: any = '';
  legends = [];
  colors = [];
  isChloropleth = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'mapChart';
  //@ts-ignore
  chartRef: Highcharts.Chart;
  nullColor = '#000';
  // password = '';
  token = null;
  username = 'greenfroginthepond@gmail.com';
  password = 'Test@123';
  orgId = '';

  envType = 'https://api.mapmycustomers.me';
  coloringType = 'pin';
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };

  chartData = [];

  chartOptions: Highcharts.Options = {
    chart: {
      map: worldMap
    },
    title: {
      text: 'Highmaps basic demo'
    },
    subtitle: {
      text: 'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">US, County, medium resolution</a>'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: 'spacingBox'
      }
    },
    legend: {
      enabled: true
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        type: 'map',
        joinBy: ['hc-key', 'code'],
        name: 'Random data',
        states: {
          hover: {
            color: '#BADA55'
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        },
        allAreas: false,
        data: _hckey()
      }
    ]
  };

  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService
  ) {
    try {
      //@ts-ignore
      this.user = JSON.parse(localStorage.getItem('user'));
      this.token = this.user.token;
      this.orgId = this.user.orgId;
    } catch (err) {
      console.log('err', err);
    }
  }

  clearStorage() {
    localStorage.clear();
  }

  getNewMap() {
    _hckey().map((e) =>
      this.hcKeyMap.set(e, { code: e, value: 0, color: this.nullColor })
    );
    return this.hcKeyMap;
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name || '');
  }

  async signIn() {
    this.status = 'SignIn....';
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'x-compression': 'true',
        'x-mmc-client': 'WEB_2'
      },
      body: new URLSearchParams({
        username: this.username,
        password: this.password,
        grant_type: 'password',
        scope: 'test',
        client_id: 'mmc-web-client-id'
      })
    };

    fetch(this.envType + '/oauth/token', options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code == 400) {
          this.status = response.message;
          return;
        }
        this.status = 'saving token';
        try {
          this.token = response.accessToken;
          this.orgId = response.orgId;
          localStorage.setItem(
            'user',
            JSON.stringify({ token: this.token, orgId: this.orgId })
          );

          this.status = 'OK';
        } catch (err) {
          this.status = err;
        }
      })
      .catch((err) => (this.status = err));
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    // this.getDataFromServer()
  }

  markerClicked(d: any) {
    console.log(d);
  }

  onLoginClick() {
    this.store.dispatch(authLogin());
  }

  onLogoutClick() {
    this.store.dispatch(authLogout());
  }

  onLanguageSelect(event: MatSelectChange) {
    this.store.dispatch(
      actionSettingsChangeLanguage({ language: event.value })
    );
  }

  run() {
    this.getDataFromServer();
  }

  async getDataFromServer() {
    this.busy = true;
    this.dataset = [];
    const f:any =    {
      mapped: true,
      includeCustomFields: true,
      $and: [{ [this.activeField]: { $ne: null } }],
      thematic: {
        breakPoints: this.manualRanges.split(',').map((e) => +e),
        size: 40000,
        buckets: this.bucketCount,
        colors: [
          '#fd7f6f',
          '#7eb0d5',
          '#b2e061',
          '#bd7ebe',
          '#ffb55a',
          '#ffee65',
          '#beb9db',
          '#fdcce5',
          '#8bd3c7'
        ],
        classification: this.activeClassification,
        field: this.activeField,
        _cardinality: 40000
      }
    }
    if(this.coloringType == 'map') {
      delete f.mapped;
    }
    const filter: String = JSON.stringify(f);
    const $aggs: any = {
      primaryGroup: { field: 'geoAddress.admin2' },
      size: 40000
    };
    if (this.aggName != 'count') {
      $aggs[this.aggName] = [this.activeField];
    }

    let total = -1;
    let iteration = 0;
    while (this.dataset.length != total) {
      const ep =
        this.envType + `/organizations/${this.orgId}/${this.activeEntity}`;
      let limit = this.coloringType == 'map' ? 0 : 100;

      let offset = iteration++ * 100;
      const columns = this.activeField;
      let params: any = {
        $filters: filter,
        $limit: limit,
        $offset: offset,
        $columns: this.selectFields,
        $aggs: JSON.stringify($aggs)
      };

      if (this.coloringType === 'pin') {
        delete params.$aggs;
      }
      let query = Object.keys(params)
        .map(
          (k: string) =>
            encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
        )
        .join('&');

      const url = `${ep}?${query}`;
      console.log(url);
      let data: any = await fetch(url, {
        headers: {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'en-GB,en;q=0.5',
          authorization: `Bearer ${this.token}`
        },
        method: 'GET'
      });

      data = await data.json();
      if(data.length!) {
        this.status = "Cannot Find Any Data, retry with different fields";
        this.legends = []
        return;
      }
      this.colors = data.thematic?.colors;
      this.legends = data.thematic?.ranges;
      console.log('this.colors', this.colors);
      if (this.coloringType == 'map') {
        const map = this.getNewMap();
        this.isChloropleth = true;
        data.aggregations.map((e: any) => {
          const hcCode = this.selectHCCode(e.key);
          map.set(hcCode, {
            code: hcCode,
            value: e.doc_count,
            color: e.theme.color
          });
        });

        // const agg2 = data.aggregations.reduce((acc:any, curr:any) => {
        //  const i = data.thematic.colors.indexOf(curr.theme.color);
        //  if(!acc[i]){
        //   acc[i] = [];
        //  }
        //  acc[i].push(this.selectHCCode(curr.key))
        // },[])
        console.log(Array.from(map, (k, v) => k[1]));
        this.chloropleth(
          Array.from(map, (k, v) => k[1]),
          data.aggregations.map((e: any) => e.theme.color)
        );
        this.busy = false;
        return;
      } else {
        this.isChloropleth = false;
      }

      console.log(data);
      let d: Array<REC> = data.data.map((d: any) => {
        const lat = d.geoPoint?.coordinates[1];
        const lng = d.geoPoint?.coordinates[0];

        let icon = {
          path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
          scale: 1,
          fillColor: d.theme?.color || '#000',
          fillOpacity: 0.8,
          strokeWeight: 1
        };
        return { lat, lng, icon, d };
      });

      this.dataset = this.dataset.concat(d);
      const start: any = this.dataset[0];

      this.lat = start.lat;
      this.lng = start.lng;

      total = data.total;
      this.status = 'this.dataset.length' + this.dataset.length + '/' + total;
      console.log('this.dataset.length', this.dataset.length, '/', total);
      if (this.dataset.length === 10000) {
        break;
      }
    }
    window.myapp = this;
    console.log(this);
    this.busy = false;
  }

  chloropleth(data: any, colors: any) {
    setTimeout(() => {
      this.chartRef.addSeries(
        {
          type: 'map',
          joinBy: ['hc-key', 'code'],
          name: 'Random data',
          states: {
            hover: {
              color: '#BADA55'
            }
          },
          dataLabels: {
            enabled: false,
            format: '{point.name}'
          },

          allAreas: false,
          data: data
        },
        true
      );
    });

    // this.chartRef.redraw()
    // this.chartRef.reflow();
  }

  selectHCCode(str: string) {
    const h = mmHash.v2(str);
    const index = h % this.hckey.length;
    return this.hckey[index];
  }
}
